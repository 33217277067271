import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Dictionary } from '@shared/types';
import { isEqual } from 'lodash';
import { GridField } from 'shared-ui/models/grid-field';
import { StorageService } from 'shared-ui/providers/storage-service';

@Component({
  selector: 'shared-datagrid-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class DatagridFilterComponent implements OnChanges {
  @Input() fields: GridField[] = [];
  @Input() filterName?: string;
  @Output() changes = new EventEmitter<Dictionary>();
  readonly form = new UntypedFormGroup({});
  formFields: GridField[] = [];
  formValues: Dictionary<any> = {};

  constructor(protected storage: StorageService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.fields) {
      const formFields = this.fields.filter(field => !!field.filter);

      formFields.forEach(field => {
        this.form.addControl(field.key, new UntypedFormControl(null));
      });

      this.formFields = formFields;

      const valuesStored = JSON.parse(this.storage.getItem(this.filterName ?? '') ?? 'null');
      this.formValues = valuesStored ?? this.getFormValues();
      this.form.patchValue(this.formValues);
    }
  }

  remove(field: GridField) {
    this.form.patchValue({ [field.key]: null });
    this.updateChanges();
  }

  reset() {
    this.form.reset();
    this.updateChanges();
  }

  apply() {
    this.updateChanges();
  }

  keyup(event: KeyboardEvent, dropdown: { close: Function }) {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      dropdown.close();
    }
  }

  changeField(value: any, field: GridField) {
    if (field.type === 'number') {
      this.patchNumberRange(value, field);
    } else if (field.type === 'select') {
      console.log(value);
    } else {
      this.form.patchValue({ [field.key]: value });
    }
  }

  private updateChanges() {
    const values = this.getFormValues();
    if (this.filterName) {
      this.storage.setItem(this.filterName, JSON.stringify(values));
    }

    setTimeout(() => {
      if (!isEqual(values, this.formValues)) {
        this.formValues = values;
        this.changes.emit(values);
      }
    });
  }

  private getFormValues() {
    const values: Record<string, any> = {};

    Object.entries(this.form.value).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        values[key] = value.length ? value : null;
      } else {
        values[key] = value || null;
      }
    });

    return values;
  }

  private patchNumberRange(value: [string, 'from' | 'to'], field: GridField) {
    const [amount, dir] = value;
    let from: number = this.form.value[field.key]?.[0];
    let to: number = this.form.value[field.key]?.[1];
    dir === 'from' ? (from = Number(amount)) : (to = Number(amount));
    if (!from && !to) {
      this.form.patchValue({ [field.key]: null });
    } else {
      this.form.patchValue({ [field.key]: [from, to] });
    }
  }
}
