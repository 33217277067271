import { Directive, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SortDirection } from '@angular/material/sort';
import { BaseEntity } from '@shared/entity/base-entity';
import { BaseRootEntity } from '@shared/entity/base-root-entity';
import { FieldOptions } from '@shared/model/field';
import { get, startCase } from 'lodash';
import { of } from 'rxjs';
import { BaseComponent } from 'shared-ui/components/base-component';
import { ApiTableDataSource } from 'shared-ui/components/datagrid/api-table-data-source';
import { DeleteDialogComponent } from 'shared-ui/components/delete-dialog/delete-dialog.component';
import { GridField } from 'shared-ui/models/grid-field';
import { CrudHttpService } from 'shared-ui/providers/crud-http-service';
import { FlashMessages } from 'shared-ui/providers/flash-messages';

@Directive()
export abstract class BaseListComponent<T extends BaseRootEntity> extends BaseComponent implements OnInit {
  data: T[] = [];
  datasource: ApiTableDataSource<T>;
  fields: GridField[] = [];
  sortField: string = 'createdAt';
  sortDirection: SortDirection = 'desc';
  isLoading = false;
  canCreate = true;
  canUpdate = true;
  canDelete = true;

  protected constructor(
    protected dataService: CrudHttpService<T>,
    protected dialogService: MatDialog,
    protected flashMessages: FlashMessages
  ) {
    super();
  }

  abstract getEntityClass(): typeof BaseEntity;

  ngOnInit(): void {
    let gridFields = this.getGridFields();
    if (Object.keys(gridFields).length === 0) {
      gridFields = this.buildGridFields(this.getEntityClass());
    }
    this.addActionsField(gridFields);
    this.onGridFieldsCreate(gridFields);
    this.fields = Object.values(gridFields);
    this.datasource = new ApiTableDataSource<T>(this.dataService, this.sortField, this.sortDirection);
    this.datasource.componentDestroyed = this.destroyed;
    this.subscribe(this.datasource.loadDataStart, () => (this.isLoading = true));
    this.subscribe(this.datasource.data, data => {
      this.data = data;
      this.isLoading = false;
    });
  }

  delete(item: T) {
    const itemId = get(item, '_id');
    const dialog = this.dialogService.open(DeleteDialogComponent);
    this.subscribe(dialog.afterClosed(), (remove: boolean) => {
      remove ? this.deleteItem(itemId) : of(true);
    });
  }

  getFieldsPick(): string[] {
    return [];
  }

  getFieldsOmit(): string[] {
    return ['_id', 'createdAt', 'updatedAt'];
  }

  protected addActionsField(fields: Record<string, GridField>) {
    fields.actions = {
      key: 'Actions',
      type: 'text',
      label: 'Actions',
      templateRef: 'actions',
    };
  }

  protected onGridFieldsCreate(fields: Record<string, GridField>) {
    fields;
  }

  protected getGridFields(): Record<string, GridField> {
    return {};
  }

  protected buildGridFields(
    entityClass: typeof BaseEntity,
    prefix = '',
    result: Record<string, GridField> = {}
  ): Record<string, GridField> {
    const pick = this.getFieldsPick();
    const fields = entityClass.getFields();
    const fieldsOptions = entityClass.getFieldsOptions();
    const labels = entityClass.getFieldsLabels();
    for (const key of fields) {
      if (pick.length && !(pick.includes(key) || pick.includes(prefix + key))) {
        continue;
      }
      const options = fieldsOptions[key];
      if (!options) {
        console.error(`Unknown field ${key}`);
        continue;
      }
      if (BaseEntity.isType(options.type)) {
        result = Object.assign(result, this.buildGridFields(options.type, `${key}.`, result));
      } else if (!this.getFieldsOmit().includes(prefix + key)) {
        result[prefix + key] = this.buildGridField(prefix + key, labels[key], options);
      }
    }
    return result;
  }

  protected buildGridField(key: string, label: string, options?: FieldOptions): GridField {
    const types: Record<string, GridField['type']> = {
      Date: 'datetime',
      Number: 'number',
      default: 'text',
    };
    const type = types[options?.type?.name ?? 'default'] ?? types.default;

    return { key, type, label, sort: true, filter: true };
  }

  protected buildSimpleField(key: string): GridField {
    return this.buildGridField(key, startCase(key));
  }

  protected deleteItem(itemId: string) {
    this.isLoading = true;
    this.subscribe(this.dataService.delete(itemId), {
      next: () => this.datasource.refresh(),
      error: () => this.flashMessages.show('Bad request'),
    });
  }
}
