<div>
  <form>
    <input #filesInput type="file" accept="*/*" multiple="" class="d-none" (change)="filesChange(filesInput)" />
    <button type="button" mat-raised-button color="primary" (click)="filesInput.click()">
      {{ 'Choose Files' | t }}
    </button>
    <button *ngIf="filesNames.length" type="button" mat-button color="primary" (click)="reset()">
      {{ 'Cancel' | t }}
    </button>
    <mat-list>
      <mat-list-item *ngFor="let item of filesNames">
        <icon mat-list-icon icon="file_present"></icon>
        <div mat-line>{{ item }}</div>
      </mat-list-item>
    </mat-list>
    <div *ngIf="filesNames.length">
      <button type="button" mat-raised-button color="accent" (click)="uploadFiles()">{{ 'Upload Files' | t }}</button>
    </div>
  </form>

  <mat-list *ngIf="product && product.files.length">
    <mat-list-item *ngFor="let item of product.files">
      <icon mat-list-icon icon="file_present"></icon>
      <div mat-line>
        <button mat-button color="warn" [matTooltip]="'Delete' | t" (click)="deleteFile(item)">
          <icon icon="clear"></icon>
        </button>
        <a [href]="item | fileUrl: 'products' : product._id" target="_blank">{{ item }}</a>
      </div>
    </mat-list-item>
  </mat-list>
</div>
