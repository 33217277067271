import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ResetPasswordRequestDto } from '@shared/domain/users/reset-password-request.dto';
import { AuthService } from 'admin/app/core/providers/auth.service';
import { FormlyBuilder } from 'shared-ui/providers/formly-builder';

@Component({
  selector: 'app-auth-reset',
  templateUrl: './auth-reset.component.html',
})
export class AuthResetComponent {
  form = this.formly.createForm<ResetPasswordRequestDto>();
  fields: FormlyFieldConfig[] = [];
  isLoading = false;
  showError = false;
  showSuccess = false;
  token = '';

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private formly: FormlyBuilder
  ) {
    this.createForm();
    this.token = this.route.snapshot.queryParamMap.get('token') ?? '';
  }

  onSubmit() {
    this.formly.processForm<ResetPasswordRequestDto>(this.form).subscribe(value => this.send(value));
  }

  protected send(value: ResetPasswordRequestDto) {
    this.showError = false;
    this.showSuccess = false;
    this.isLoading = true;
    value.token = this.token;
    this.authService.resetPassword(value).subscribe(
      () => {
        this.isLoading = false;
        this.showSuccess = true;
      },
      () => {
        this.isLoading = false;
        this.showError = true;
      }
    );
  }

  protected createForm(): void {
    this.fields = [
      {
        key: 'password',
        type: 'input',
        props: {
          label: 'Password',
          type: 'password',
          required: true,
          minLength: 8,
          appearance: 'outline',
        },
      },
      {
        key: 'passwordRepeat',
        type: 'input',
        validators: {
          same: this.formly.createSameValidator(this.form, 'password', 'Passwords do not match'),
        },
        props: {
          label: 'Password Repeat',
          type: 'password',
          required: true,
          minLength: 8,
          appearance: 'outline',
        },
      },
    ];
    this.formly.create(this.fields);
  }
}
