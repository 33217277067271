import { Component } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { LoginRequestDto } from '@shared/domain/users/login-request.dto';
import { AuthService } from 'admin/app/core/providers/auth.service';
import { FormlyBuilder } from 'shared-ui/providers/formly-builder';

@Component({
  selector: 'app-auth-login',
  templateUrl: './login.component.html',
})
export class LoginComponent {
  form = this.formly.createForm<LoginRequestDto>();
  fields: FormlyFieldConfig[] = [];
  isLoading = false;
  showError = false;

  constructor(
    private authService: AuthService,
    private formly: FormlyBuilder
  ) {
    this.createForm();
  }

  onSubmit() {
    this.formly.processForm<LoginRequestDto>(this.form).subscribe(value => this.send(value));
  }

  protected send(value: LoginRequestDto) {
    this.showError = false;
    this.isLoading = true;
    this.authService.login(value).subscribe(
      () => {
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
        this.showError = true;
      }
    );
  }

  protected createForm(): void {
    this.fields = [
      {
        key: 'login',
        type: 'input',
        validators: { validation: ['email'] },
        props: {
          label: 'Email',
          required: true,
          appearance: 'outline',
        },
      },
      {
        key: 'password',
        type: 'input',
        props: {
          label: 'Password',
          type: 'password',
          required: true,
          appearance: 'outline',
        },
      },
    ];
    this.formly.create(this.fields);
  }
}
