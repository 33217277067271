import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ChangeEmailRequestDto } from '@shared/domain/users/change-email-request.dto';
import { ChangePasswordRequestDto } from '@shared/domain/users/change-password-request.dto';
import { ForgotPasswordRequestDto } from '@shared/domain/users/forgot-password-request.dto';
import { LoginRequestDto } from '@shared/domain/users/login-request.dto';
import { LoginResponseDto } from '@shared/domain/users/login-response.dto';
import { ResetPasswordRequestDto } from '@shared/domain/users/reset-password-request.dto';
import { UserPayload } from '@shared/domain/users/user-payload';
import { UserPoolsEnum } from '@shared/domain/users/user-pools.enum';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { storageKeys } from 'shared-ui/models/storage';
import { StorageService } from 'shared-ui/providers/storage-service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _isLoggedIn = false;
  public loggedIn = new BehaviorSubject(false);
  public redirectUrl = '/';

  constructor(
    private http: HttpClient,
    private router: Router,
    private storageService: StorageService
  ) {
    this.onInit();
  }

  get isLoggedIn(): boolean {
    return this._isLoggedIn;
  }

  get userName(): string {
    const user = this.getUser();
    return user?.name ?? '';
  }

  getUser(): UserPayload | null {
    return JSON.parse(this.storageService.getItem(storageKeys.USER) || 'null');
  }

  checkUserSession(): Observable<boolean> {
    return this.http.get(`auth/is-logged-in`).pipe(
      map(() => true),
      catchError(() => {
        void this.logout();
        return of(true);
      })
    );
  }

  login(values: LoginRequestDto): Observable<boolean> {
    values.poolId = UserPoolsEnum.Admin;
    return this.http.post<LoginResponseDto>('auth/sign-in', values).pipe(
      map(response => {
        this.storageService.setItem(storageKeys.AUTH, response.idToken);
        this.storageService.setItem(storageKeys.REFRESH_TOKEN, response.refreshToken);
        this.storageService.setItem(storageKeys.USER, JSON.stringify(response.user));
        this._isLoggedIn = true;
        this.loggedIn.next(this._isLoggedIn);
        void this.router.navigate([this.redirectUrl]);
        return true;
      })
    );
  }

  logout(): Promise<boolean> {
    this.storageService.clear();
    this._isLoggedIn = false;
    this.loggedIn.next(this._isLoggedIn);
    this.redirectUrl = window.location.pathname;
    return this.router.navigate(['auth/login']);
  }

  changeEmail(values: ChangeEmailRequestDto): Observable<boolean> {
    return this.http.post<boolean>('auth/change-email', values);
  }

  changePassword(values: ChangePasswordRequestDto): Observable<boolean> {
    return this.http.post<boolean>('auth/change-password', values);
  }

  forgotPassword(values: ForgotPasswordRequestDto): Observable<boolean> {
    values.poolId = UserPoolsEnum.Admin;
    return this.http.post<boolean>('auth/forgot-password', values);
  }

  resetPassword(values: ResetPasswordRequestDto): Observable<boolean> {
    values.poolId = UserPoolsEnum.Admin;
    return this.http.post<boolean>('auth/reset-password', values);
  }

  private onInit(): void {
    this._isLoggedIn = !!this.storageService.getItem(storageKeys.AUTH);
    this.loggedIn.next(this._isLoggedIn);
  }
}
