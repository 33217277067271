import {
  AfterContentInit, AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { AppFormInputDirective } from 'shared-ui/directives/app-form-input.directive';
import { FormErrors } from 'shared-ui/providers/form-errors';

@Component({
  selector: 'app-form-field',
  templateUrl: './app-form-field.component.html',
})
export class AppFormFieldComponent implements AfterViewInit, OnDestroy {
  dirty = false;

  @Input()
  error: string;

  @ViewChild('formField')
  formField: ElementRef<HTMLElement>;

  @ContentChild(AppFormInputDirective)
  input: AppFormInputDirective;

  inputId: string;

  @Input()
  label: string;

  protected onSubmit: Subscription;

  protected onValueChange?: Subscription;

  required = false;

  tError = [];

  valid = false;

  constructor(private formErrors: FormErrors) {
  }

  get showError(): boolean {
    if (!this.dirty) return false;
    return !this.valid;
  }

  ngAfterViewInit(): void {
    if (this.input) {
      setTimeout(() => {
        this.inputId = this.input.id
        this.required = this.input.element.required;
      });
      this.input.element.name = this.input.control.name as string;
      this.valid = this.input.control.valid ?? false;
      this.onSubmit = this.input.form.ngSubmit.subscribe(() =>
        this.changeInput(true)
      );
      if (this.input.control.valueChanges) {
        this.onValueChange = this.input.control.valueChanges.subscribe(() =>
          this.changeInput()
        );
      }
    } else {
      console.warn('Missing formInput for this form field:');
      console.log(this.formField?.nativeElement);
    }
  }

  ngOnDestroy(): void {
    if (this.onSubmit) this.onSubmit.unsubscribe();
    if (this.onValueChange) this.onValueChange.unsubscribe();
  }

  protected changeInput(dirty?: boolean): void {
    this.error = this.formErrors.getError(this.input.control.errors);
    this.dirty = dirty ?? this.input.control.dirty ?? false;
    this.valid = this.input.control.valid ?? false;
    this.markInputValid();
  }

  protected markInputValid(): void {
    const inputElement = this.input.element;
    if (this.valid) {
      inputElement.classList.remove('is-invalid');
      inputElement.classList.add('is-valid');
    } else {
      inputElement.classList.remove('is-valid');
      inputElement.classList.add('is-invalid');
    }
  }
}
