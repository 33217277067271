import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SortDirection } from '@angular/material/sort';
import { deliveries, payments } from '@shared/domain/orders/constants';
import { OrderStateEnum } from '@shared/domain/orders/order-state';
import { OrderEntity } from '@shared/domain/orders/order.entity';
import { invert, unset } from 'lodash';
import { BaseListComponent } from 'shared-ui/components/base-list-component';
import { OrdersService } from 'shared-ui/domain/orders/orders.service';
import { GridField } from 'shared-ui/models/grid-field';
import { FlashMessages } from 'shared-ui/providers/flash-messages';

@Component({
  selector: 'app-orders-list',
  templateUrl: './orders-list.component.html',
})
export class OrdersListComponent extends BaseListComponent<OrderEntity> {
  canCreate = false;
  canDelete = false;
  sortField = 'code';
  sortDirection: SortDirection = 'desc';
  stateLabelsEnum = invert(OrderStateEnum);

  constructor(
    protected dataService: OrdersService,
    protected dialogService: MatDialog,
    protected flashMessages: FlashMessages
  ) {
    super(dataService, dialogService, flashMessages);
  }

  getEntityClass() {
    return OrderEntity;
  }

  protected getGridFields(): Record<string, GridField> {
    return {
      code: this.buildSimpleField('code'),
      createdAt: this.buildGridField('createdAt', 'Created', { required: true, type: Date }),
      customerName: this.buildSimpleField('customerName'),
      delivery: this.buildGridField('delivery.title', 'Delivery'),
      payment: this.buildGridField('payment.title', 'Payment'),
      total: this.buildSimpleField('total'),
      state: this.buildSimpleField('state'),
    };
  }

  onGridFieldsCreate(fields: Record<string, GridField>) {
    unset(fields, 'actions');
    fields.customerName.templateRef = 'customer';
    fields.total.templateRef = 'total';
    fields.delivery.type = 'select';
    fields.delivery.options = deliveries.map(delivery => {
      return { label: delivery.title, value: delivery.title };
    });
    fields.payment.type = 'select';
    fields.payment.options = payments.map(payment => {
      return { label: payment.title, value: payment.title };
    });
    fields.state.type = 'select';
    fields.state.templateRef = 'state';
    fields.state.translate = true;
    fields.state.options = Object.entries(OrderStateEnum).map(([key, value]) => {
      return { label: key, value: value + '' };
    });
  }
}
