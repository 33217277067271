import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Observer, Subject, Subscription } from 'rxjs';

type Next<T> = (value: T) => void;

@Injectable()
export abstract class BaseComponent implements OnDestroy {
  protected readonly destroyed = new Subject<void>();

  protected readonly subscriptions = new Subscription();

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
    this.subscriptions.unsubscribe();
  }

  protected subscribe<T>(observable: Observable<T>, next: Next<T>): Subscription;
  protected subscribe<T>(observable: Observable<T>, observer: Partial<Observer<T>>): Subscription;
  protected subscribe<T>(observable: Observable<T>, observerOrNext: Partial<Observer<T>> | Next<T>): Subscription {
    const observer = observerOrNext instanceof Function ? { next: observerOrNext } : observerOrNext;
    this.subscriptions.add(observable.subscribe(observer));
    return this.subscriptions;
  }
}
