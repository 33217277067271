import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductEntity } from '@shared/domain/products/product.entity';
import { ProductCopyComponent } from 'admin/app/views/catalog/components/products/product-copy/product-copy.component';
import { Icon } from 'shared-ui/components/icon/icon.component';
import { ProductsService } from 'shared-ui/domain/products/products.service';
import { BaseComponent } from 'shared-ui/components/base-component';
import { FlashMessages } from 'shared-ui/providers/flash-messages';

interface Section {
  id: string;
  icon: Icon;
  title: string;
}

@Component({
  selector: 'admin-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss'],
})
export class ProductDetailComponent extends BaseComponent {
  isLoading = false;
  product?: ProductEntity;
  productId = '';
  section?: Section;
  sections: Section[] = [];

  constructor(
    private dataService: ProductsService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private flashMessages: FlashMessages
  ) {
    super();
    this.setSections();
    this.trackRoute();
  }

  goToSection(section: string) {
    this.router.navigate([], { queryParams: { section } });
  }

  duplicate(product: ProductEntity) {
    const modal = this.dialog.open(ProductCopyComponent, { disableClose: true, minWidth: '320px' });
    modal.componentInstance.data = product;
    this.subscribe(modal.afterClosed(), result => {
      if (result instanceof ProductEntity) {
        this.flashMessages.show('New product was created.');
        this.router.navigate(['/catalog/products/' + result._id]);
      }
    });
  }

  private setSections() {
    this.sections = [
      { id: 'params', icon: 'edit', title: 'Settings and params' },
      { id: 'editor', icon: 'description', title: 'Editor description' },
      { id: 'images', icon: 'image', title: 'Images' },
      { id: 'files', icon: 'folder_copy', title: 'Files' },
      { id: 'accessories', icon: 'inventory', title: 'Accessories' },
    ];
  }

  private trackRoute() {
    this.subscribe(this.route.paramMap, paramMap => {
      this.productId = paramMap.get('id') ?? '';
      this.loadDetail();
    });
    this.subscribe(this.route.queryParamMap, queryMap => {
      const section = queryMap.get('section') ?? '';
      this.section = this.sections.find(item => item.id === section);
    });
  }

  private loadDetail() {
    this.isLoading = true;
    this.subscribe(this.dataService.get(this.productId), product => {
      this.product = product;
      this.isLoading = false;
    });
  }
}
