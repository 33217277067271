import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, OnInit } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { CategoryEntity } from '@shared/domain/products/category.entity';
import { CategoriesService } from 'shared-ui/domain/products/categories.service';

@Component({
  selector: 'app-catalog-categories-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
})
export class CategoriesOverviewComponent implements OnInit {
  categories: CategoryEntity[] = [];

  expanded = true;

  nestedTreeControl: NestedTreeControl<CategoryEntity>;

  nestedDataSource: MatTreeNestedDataSource<CategoryEntity>;

  constructor(private categoriesService: CategoriesService) {
    this.nestedTreeControl = new NestedTreeControl(this._getChildren);
    this.nestedDataSource = new MatTreeNestedDataSource();
  }

  ngOnInit(): void {
    this.categoriesService.getTree().subscribe(tree => {
      this.categories = tree;
      this.nestedDataSource.data = this.categories;
      this.nestedTreeControl.dataNodes = this.nestedDataSource.data;
      this.expandAll();
    });
  }

  expandAll(): void {
    this.nestedTreeControl.expandAll();
    this.expanded = true;
  }

  collapseAll(): void {
    this.nestedTreeControl.collapseAll();
    this.expanded = false;
  }

  toggleAll(): void {
    if (this.expanded) {
      this.collapseAll();
    } else {
      this.expandAll();
    }
  }

  hasNestedChild(_: number, node: CategoryEntity): boolean {
    return (node.children?.length ?? 0) > 0;
  }

  private _getChildren(node: CategoryEntity): CategoryEntity[] {
    return node.children ?? [];
  }
}
