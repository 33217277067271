import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { OrderStateEnum } from '@shared/domain/orders/order-state';
import { OrderEntity } from '@shared/domain/orders/order.entity';
import { OrdersEditComponent } from 'admin/app/orders/components/orders-edit/orders-edit.component';
import { OrdersService } from 'shared-ui/domain/orders/orders.service';
import { environment } from 'admin/environments/environment';
import { invert } from 'lodash';
import { BaseComponent } from 'shared-ui/components/base-component';

@Component({
  selector: 'app-orders-detail',
  templateUrl: './orders-detail.component.html',
  styleUrls: ['./orders-detail.component.scss'],
})
export class OrdersDetailComponent extends BaseComponent implements OnInit {
  imagesUrl = environment.imagesUrl;
  isLoading = false;
  order?: OrderEntity;
  address: string;
  readonly stateLabelsEnum = invert(OrderStateEnum);

  constructor(
    private dataService: OrdersService,
    private route: ActivatedRoute,
    private dialogFactory: MatDialog
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscribe(this.route.paramMap, params => this.loadOrder(params.get('id') ?? ''));
  }

  edit() {
    const dialog = this.dialogFactory.open(OrdersEditComponent, {
      data: this.order,
      autoFocus: false,
      disableClose: true,
      width: '480px',
    });
    this.subscribe(dialog.afterClosed(), (result: boolean) => {
      if (result) {
        this.loadOrder(this.order?._id ?? '');
      }
    });
  }

  private loadOrder(id: string): void {
    this.isLoading = true;
    this.subscribe(this.dataService.get(id), order => {
      if (order) {
        this.order = order;
        const address = order.customer.deliveryAddress;
        this.address = `${address.street}, ${address.city}`;
      }
      this.isLoading = false;
    });
  }
}
