import { UntypedFormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { isNotEmpty } from 'class-validator';
import { cloneDeep, isString } from 'lodash';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { debounceTime, delay } from 'rxjs/operators';
import { FindFilter, FindPage, FindSort } from 'shared-ui/models/find';
import { CrudHttpService } from 'shared-ui/providers/crud-http-service';

export class ApiTableDataSource<T> {
  data = new BehaviorSubject<T[]>([]);
  componentDestroyed = new Subject<void>();
  loadDataStart = new Subject<void>();
  delay = 250;
  filterDelay = 0;
  private _filter?: UntypedFormGroup;
  private _paginator?: MatPaginator;
  private _sort?: MatSort;

  constructor(
    public dataService: CrudHttpService<T>,
    public sortField: string = 'createdAt',
    public sortDirection: SortDirection = 'desc'
  ) {}

  get filter() {
    return this._filter;
  }

  set filter(filter: UntypedFormGroup | undefined) {
    this._filter = filter;
    if (filter) {
      filter.valueChanges.pipe(takeUntil(this.componentDestroyed), debounceTime(this.filterDelay)).subscribe(() => {
        this.reset();
        this.loadData();
      });
    }
  }

  get paginator() {
    return this._paginator;
  }

  set paginator(paginator: MatPaginator | undefined) {
    this._paginator = paginator;
    if (paginator) {
      paginator.page.pipe(takeUntil(this.componentDestroyed)).subscribe(page => this.loadData(page));
    }
  }

  get sort() {
    return this._sort;
  }

  set sort(sort: MatSort | undefined) {
    this._sort = sort;
    if (sort) {
      sort.sortChange.pipe(takeUntil(this.componentDestroyed)).subscribe(sort => {
        this.sortField = sort.active;
        this.sortDirection = sort.direction;
        this.loadData();
      });
    }
  }

  reset() {
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }
  }

  refresh() {
    this.loadData();
  }

  private loadData(page?: FindPage) {
    page = this.getPage(page);
    const filter: FindFilter = this.getFilter(this.filter?.value ?? {});
    const sort: FindSort = { active: this.sortField, direction: this.sortDirection };
    this.loadDataStart.next();
    this.dataService
      .find({ filter, page, sort })
      .pipe(takeUntil(this.componentDestroyed), delay(this.delay))
      .subscribe(result => {
        if (this.paginator) this.paginator.length = result.total;
        this.data.next(result.items);
      });
  }

  private getPage(page?: FindPage): FindPage {
    return (
      page ?? {
        pageIndex: this.paginator?.pageIndex ?? 0,
        pageSize: this.paginator?.pageSize ?? 25,
        length: this.paginator?.length ?? 0,
      }
    );
  }

  private getFilter(data: Record<string, any>): FindFilter {
    const filter = cloneDeep(data);
    for (const key in filter) {
      const value = filter[key];
      if (isString(value) && isNotEmpty(value)) {
        filter[key] = `*${value}`;
      }
    }
    return filter;
  }
}
