<div class="action-bar action-bar-full-w">
  <a routerLink="/catalog/products" mat-icon-button color="primary" [matTooltip]="'Back to Overview' | t">
    <icon icon="arrow_back"></icon>
  </a>
  <span class="action-bar-title">{{ 'Products' | t }} - {{ product?.name }}</span>
  <button *ngIf="product" mat-button color="primary" (click)="duplicate(product)">
    <icon icon="content_copy"></icon><span class="label">{{ 'Duplicate' | t }}</span>
  </button>
</div>

<div *ngIf="section && product">
  <mat-card class="col-xl-8">
    <mat-card-header>
      <mat-card-title><icon [icon]="section.icon"></icon> {{ section.title | t }}</mat-card-title>
      <span class="flex-spacer"></span>
      <button mat-button color="primary" (click)="goToSection('')" class="right-float">
        <icon icon="arrow_back"></icon>&nbsp;{{ 'Back' | t }}
      </button>
    </mat-card-header>
    <mat-card-content class="pt-3">
      <admin-product-detail-params
        *ngIf="section.id === 'params'"
        [product]="product"
        (closed)="goToSection('')"></admin-product-detail-params>
      <admin-product-detail-editor
        *ngIf="section.id === 'editor'"
        [product]="product"
        (closed)="goToSection('')"></admin-product-detail-editor>
      <admin-product-detail-images *ngIf="section.id === 'images'" [product]="product"></admin-product-detail-images>
      <admin-product-detail-files *ngIf="section.id === 'files'" [product]="product"></admin-product-detail-files>
      <admin-product-accessories
        *ngIf="section.id === 'accessories'"
        [product]="product"
        (closed)="goToSection('')"></admin-product-accessories>
    </mat-card-content>
  </mat-card>
</div>

<div class="row" *ngIf="!section">
  <div *ngFor="let item of sections" class="col-lg-4">
    <mat-card>
      <mat-card-header>
        <div mat-card-avatar><icon [icon]="item.icon"></icon></div>
        <mat-card-title>{{ item.title | t }}</mat-card-title>
      </mat-card-header>
      <mat-card-footer>
        <div class="mat-card-footer-button" mat-ripple (click)="goToSection(item.id)">
          <span>{{ 'Edit' | t }}</span>
          <icon icon="arrow_forward"></icon>
        </div>
      </mat-card-footer>
    </mat-card>
  </div>
</div>
