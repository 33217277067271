import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SortDirection } from '@angular/material/sort';
import { UserEntity } from '@shared/domain/users/user.entity';
import { BaseListComponent } from 'shared-ui/components/base-list-component';
import { AdminUsersService } from 'shared-ui/domain/users/admin-users.service';
import { GridField } from 'shared-ui/models/grid-field';
import { FlashMessages } from 'shared-ui/providers/flash-messages';

@Component({
  selector: 'admin-users-list',
  templateUrl: './users-list.component.html',
})
export class UsersListComponent extends BaseListComponent<UserEntity> {
  canDelete = false;
  sortField = 'email';
  sortDirection: SortDirection = 'asc';

  constructor(
    protected dataService: AdminUsersService,
    protected dialogService: MatDialog,
    protected flashMessages: FlashMessages
  ) {
    super(dataService, dialogService, flashMessages);
  }

  getEntityClass() {
    return UserEntity;
  }

  getFieldsOmit() {
    return ['_id', 'createdAt', 'updatedAt', 'poolId', 'hashedPassword'];
  }

  onGridFieldsCreate(fields: Record<keyof UserEntity, GridField>) {
    fields.name.label = 'Username';
    fields.roles.type = 'select';
    fields.roles.options = [{ label: 'admin', value: 'admin' }];
    fields.active.templateRef = 'active';
    fields.active.filter = false;
  }
}
