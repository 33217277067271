<div class="action-bar action-bar-full-w">
  <a routerLink="/catalog/categories" mat-icon-button color="primary" [matTooltip]="'Back to Overview' | t">
    <icon icon="arrow_back"></icon>
  </a>
  <span class="action-bar-title">{{ 'Categories' | t }} - {{ 'Detail' | t }}</span>
  <a mat-button color="warn" (click)="onDeleteCategory()">
    <icon icon="delete_outline"></icon>
    {{ 'Delete Category' | t }}</a
  >
</div>

<mat-card>
  <mat-tab-group>
    <mat-tab [label]="'Basic Info' | t">
      <div class="p-3">
        <app-catalog-categories-form
          *ngIf="category"
          [category]="category"
          class="d-block col-lg-6"
          (submitted)="onSubmit($event)"></app-catalog-categories-form>
      </div>
    </mat-tab>
    <mat-tab [label]="'Parameters' | t">
      <div *ngIf="category" [formGroup]="form" class="p-3">
        <div class="row form-group">
          <div class="col-2"><b>ID</b></div>
          <div class="col-2">
            <b>{{ 'Label' | t }}</b>
          </div>
          <div class="col-2">
            <b>{{ 'Type' | t }}</b>
          </div>
          <div class="col-5">
            <b>{{ 'Definition' | t }}</b>
          </div>
        </div>
        <div *ngFor="let param of parentsParams" class="row form-group">
          <div class="col-2">
            <input formInput [value]="param.id" disabled />
          </div>
          <div class="col-2">
            <input formInput [value]="param.label" disabled />
          </div>
          <div class="col-2">
            <select formInput disabled>
              <ng-container *ngFor="let type of paramsTypes">
                <option [selected]="param.type === type.id">
                  {{ type.label | t }}
                </option>
              </ng-container>
            </select>
          </div>
          <div class="col-5">
            <input formInput [value]="param.def" disabled />
          </div>
        </div>
        <div formArrayName="params" *ngFor="let param of params.controls; let i = index">
          <div [formGroupName]="i" class="row">
            <div class="col-2">
              <app-form-field>
                <input formInput formControlName="id" />
              </app-form-field>
            </div>
            <div class="col-2">
              <app-form-field>
                <input formInput formControlName="label" />
              </app-form-field>
            </div>
            <div class="col-2">
              <app-form-field>
                <select formInput formControlName="type">
                  <ng-container *ngFor="let type of paramsTypes">
                    <option [value]="type.id">{{ type.label | t }}</option>
                  </ng-container>
                </select>
              </app-form-field>
            </div>
            <div class="col-5">
              <app-form-field><input formInput formControlName="def" /></app-form-field>
            </div>
            <div class="col-1">
              <button mat-button color="warn" (click)="deleteRow(i)"><icon icon="clear"></icon></button>
            </div>
          </div>
        </div>
        <div class="form-group">
          <button mat-button color="primary" (click)="addRow()"><icon icon="add_box"></icon></button>
        </div>
        <div>
          <button mat-raised-button color="primary" (click)="saveParams()">
            {{ 'Save' | t }}
          </button>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-card>
