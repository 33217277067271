import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { Dictionary } from '@shared/types';

@Component({
  selector: 'app-form-buttons',
  templateUrl: './app-form-buttons.component.html',
})
export class AppFormButtonsComponent {
  @HostBinding('class') hostClass = 'mat-form-buttons';
  @Input() isLoading = false;
  @Input() btnStyle: Dictionary = {};
  @Input() btnClass: string | string[] | Dictionary = {};
  @Input() color: ThemePalette = 'primary';
  @Input() label = 'Save';
  @Input() showCancel = false;
  @Input() centered = false;
  @Output() cancel = new EventEmitter();
}
