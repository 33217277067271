import { Injectable } from '@angular/core';
import { InspectionBoilerType } from '@shared/domain/inspections/inspection-boiler-type';
import { InspectionStatus } from '@shared/domain/inspections/inspection-status';
import { InspectionEntity } from '@shared/domain/inspections/inspection.entity';
import { SelectOption } from 'shared-ui/models/select-option';
import { CrudHttpService } from 'shared-ui/providers/crud-http-service';

@Injectable()
export class InspectionsService extends CrudHttpService<InspectionEntity> {
  protected baseUrl = 'inspections';
  protected entityClass = InspectionEntity;

  getStatusOptions(): SelectOption[] {
    return Object.values(InspectionStatus).map(opt => {
      return { label: this.translator.translate(opt), value: opt };
    });
  }

  getTypeOptions(): SelectOption[] {
    return Object.values(InspectionBoilerType).map(opt => {
      return { label: opt, value: opt };
    });
  }
}
