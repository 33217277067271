import { Injectable } from '@angular/core';
import { OrderEntity } from '@shared/domain/orders/order.entity';
import { FindFilter } from 'shared-ui/models/find';
import { CrudHttpService, HttpFilter } from 'shared-ui/providers/crud-http-service';

@Injectable()
export class OrdersService extends CrudHttpService<OrderEntity> {
  protected baseUrl = 'orders';
  protected entityClass = OrderEntity;

  protected buildFilter(filter: FindFilter = {}): HttpFilter {
    if (Array.isArray(filter.state)) {
      filter.state = filter.state.map(item => `Number(${item})`);
    }
    return super.buildFilter(filter);
  }
}
