import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ProductEntity } from '@shared/domain/products/product.entity';
import { ProductsService } from 'shared-ui/domain/products/products.service';
import { BaseComponent } from 'shared-ui/components/base-component';
import { FormlyBuilder } from 'shared-ui/providers/formly-builder';

type CopyFormFields = { name: string; includeFiles: boolean };

@Component({
  selector: 'admin-product-copy',
  templateUrl: './product-copy.component.html',
})
export class ProductCopyComponent extends BaseComponent {
  form = this.formlyBuilder.createForm<CopyFormFields>();
  formFields = this.getFields();
  isLoading = false;
  product?: ProductEntity;

  constructor(
    public dialog: MatDialogRef<any>,
    private dataService: ProductsService,
    private formlyBuilder: FormlyBuilder
  ) {
    super();
  }

  set data(value: ProductEntity) {
    this.product = value;
  }

  duplicate() {
    this.form.markAllAsTouched();
    const name = this.form.value.name;
    const includeFiles = this.form.value.includeFiles ?? true;
    if (this.form.valid && this.product && name) {
      this.isLoading = true;
      this.subscribe(this.dataService.duplicate(this.product._id, name, includeFiles), result => {
        this.isLoading = false;
        this.dialog.close(result);
      });
    }
  }

  private getFields() {
    const fields: FormlyFieldConfig[] = [
      {
        key: 'name',
        type: 'input',
        props: { label: 'Name', required: true },
        validators: {
          same: {
            expression: (control: FormControl) => {
              return this.product?.name !== control.value;
            },
            message: this.formlyBuilder.translate('The name must be different from the original.'),
          },
        },
      },
      {
        key: 'includeFiles',
        type: 'checkbox',
        defaultValue: true,
        props: {
          label: 'Including files and images',
          appearance: '',
        },
      },
    ];
    return this.formlyBuilder.create(fields);
  }
}
