import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CategoriesService } from 'shared-ui/domain/products/categories.service';
import { FlashMessages } from 'shared-ui/providers/flash-messages';

@Component({
  selector: 'app-catalog-categories-new',
  templateUrl: './new.component.html',
})
export class CategoriesNewComponent {
  constructor(
    private categoriesService: CategoriesService,
    private flashMessages: FlashMessages,
    private router: Router
  ) {}

  onSubmit(formData: Record<string, unknown>) {
    this.categoriesService.create(formData).subscribe(
      () => {
        this.flashMessages.show('New category was created.');
        void this.router.navigate(['catalog/categories']);
      },
      () => {
        this.flashMessages.show('New category was not created. Try again later.');
      }
    );
  }
}
