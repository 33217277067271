<div class="action-bar action-bar-full-w">
  <a routerLink="/orders" mat-icon-button color="primary" [matTooltip]="'Back to Overview' | t">
    <icon icon="arrow_back"></icon>
  </a>
  <span class="action-bar-title">{{ 'Orders' | t }} - {{ order?.code }}</span>
  <button mat-button color="primary" (click)="edit()"><icon icon="edit"></icon> {{ 'Edit' | t }}</button>
  <mat-chip *ngIf="order" color="primary">{{ stateLabelsEnum[order.state] | t }}</mat-chip>
</div>

<mat-progress-bar *ngIf="isLoading" mode="indeterminate" color="primary"></mat-progress-bar>

<div *ngIf="order" class="row">
  <div class="col-md-4 mb-4">
    <mat-card>
      <mat-card-header>
        <mat-card-title>{{ 'Contact of customer' | t }}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        Email:
        <a href="mailto:{{ order.customer.email }}">{{ order.customer.email }}</a>
        <br />
        {{ 'Phone' | t }}: {{ order.customer.phone }}
        <br />
        &nbsp;
        <br />
        &nbsp;
      </mat-card-content>
    </mat-card>
  </div>
  <div class="col-md-4 mb-4">
    <mat-card>
      <mat-card-header>
        <mat-card-title
          ><span>{{ 'Billing address' | t }}</span></mat-card-title
        >
      </mat-card-header>
      <mat-card-content>
        <b>{{ order.customer.billingAddress.name }}</b>
        <br />
        {{ order.customer.billingAddress.street }}
        <br />
        {{ order.customer.billingAddress.city }}
        {{ order.customer.billingAddress.postalcode }}
        <br />
        {{ order.customer.billingAddress.country }}
      </mat-card-content>
    </mat-card>
  </div>
  <div class="col-md-4 mb-4">
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          <span>{{ 'Delivery address' | t }}&nbsp;&nbsp;</span>
          <a [href]="'https://www.google.com/maps/search/' + address" target="_blank" [matTooltip]="'Map' | t">
            <icon icon="map"></icon>
          </a>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <b>{{ order.customer.deliveryAddress.name }}</b>
        <br />
        {{ order.customer.deliveryAddress.street }}
        <br />
        {{ order.customer.deliveryAddress.city }}
        {{ order.customer.deliveryAddress.postalcode }}
        <br />
        {{ order.customer.deliveryAddress.country }}
      </mat-card-content>
    </mat-card>
  </div>
</div>

<mat-card *ngIf="order">
  <mat-card-header>
    <mat-card-title>{{ 'Items' | t }}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="table-responsive">
      <table class="mat-table">
        <thead>
          <tr>
            <th>{{ 'Image' | t }}</th>
            <th>{{ 'Title' | t }}</th>
            <th class="ta-r">{{ 'Price/Piece' | t }}</th>
            <th class="ta-r">{{ 'Qt.' | t }}</th>
            <th class="ta-r">{{ 'Total price' | t }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let product of order.products">
            <td>
              <img src="{{ imagesUrl }}/products/{{ product.id }}/{{ product.image }}" alt="product" />
            </td>
            <td>
              <a [href]="'/catalog/products/' + product.id" target="_blank">{{ product.name }}</a>
            </td>
            <td class="ta-r">{{ product.price * 1.21 | currency: 'CZK' }}</td>
            <td class="ta-r">{{ product.count }}</td>
            <td class="ta-r">
              {{ product.price * 1.21 * product.count | currency: 'CZK' }}
            </td>
          </tr>
          <tr>
            <td>
              <img src="/assets/shipments/{{ order.delivery.id }}.png" alt="delivery" />
            </td>
            <td>{{ order.delivery.title }}</td>
            <td></td>
            <td></td>
            <td class="ta-r">{{ order.delivery.price | currency: 'CZK' }}</td>
          </tr>
          <tr>
            <td></td>
            <td>{{ order.payment.title }}</td>
            <td></td>
            <td></td>
            <td class="ta-r">{{ order.payment.price | currency: 'CZK' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </mat-card-content>
  <div class="p-3 ta-r">
    <b>{{ 'Amount to be paid' | t }}: {{ order.total | currency: 'CZK' }}</b>
  </div>
</mat-card>
