import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ProductEntity } from '@shared/domain/products/product.entity';
import { ProductsService } from 'shared-ui/domain/products/products.service';
import { BaseComponent } from 'shared-ui/components/base-component';
import { FlashMessages } from 'shared-ui/providers/flash-messages';
import { FormlyBuilder } from 'shared-ui/providers/formly-builder';

type ProductFormFields = Pick<ProductEntity, 'description'>;

@Component({
  selector: 'admin-product-detail-editor',
  templateUrl: './product-detail-editor.component.html',
})
export class ProductDetailEditorComponent extends BaseComponent implements OnChanges {
  form = this.formly.createForm<ProductFormFields>({ description: new FormControl() });
  isSaving = false;
  @Input()
  product?: ProductEntity;
  @Output()
  readonly closed = new EventEmitter();

  constructor(
    private dataService: ProductsService,
    private flashMessages: FlashMessages,
    private formly: FormlyBuilder
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.product && this.product) {
      this.form.patchValue(this.product);
    }
  }

  close() {
    this.closed.emit();
  }

  save() {
    this.form.markAllAsTouched();
    if (this.product && this.form.valid) {
      const value = this.formly.getFormValue<ProductFormFields>(this.form);
      this.isSaving = true;
      this.product.description = value.description;
      this.subscribe(this.dataService.update(this.product._id, value), () => {
        this.flashMessages.show('Changes were saved.');
        this.isSaving = false;
        this.closed.emit();
      });
    }
  }
}
