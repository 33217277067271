import { AfterViewInit, Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'admin/app/core/providers/auth.service';
import { of, switchMap, timer } from 'rxjs';
import { ProgressService } from 'shared-ui/providers/progress.service';

@Component({
  selector: 'admin-app-root',
  templateUrl: './admin-app.component.html',
  styleUrls: ['./admin-app.component.scss'],
})
export class AdminAppComponent implements AfterViewInit {
  static readonly fiveMinutes = 5 * 60 * 1000;
  isLoggedIn = this.authService.isLoggedIn;
  menuOpened = false;

  constructor(
    private authService: AuthService,
    private progressService: ProgressService,
    private router: Router
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.menuOpened = false;
      }
    });
  }

  ngAfterViewInit() {
    this.authService.loggedIn.subscribe(status => {
      setTimeout(() => {
        this.isLoggedIn = status;
      });
    });
    this.checkUserSession();
  }

  protected checkUserSession() {
    this.progressService.pageLoadStart();
    timer(0, AdminAppComponent.fiveMinutes)
      .pipe(
        switchMap(() => {
          return this.authService.isLoggedIn ? this.authService.checkUserSession() : of(true);
        })
      )
      .subscribe(() => {
        this.progressService.pageLoadEnd();
      });
  }
}
