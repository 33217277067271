import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InspectionEntity } from '@shared/domain/inspections/inspection.entity';
import { InspectionsService } from 'shared-ui/domain/inspections/inspections.service';
import { BaseListComponent } from 'shared-ui/components/base-list-component';
import { GridField } from 'shared-ui/models/grid-field';
import { FlashMessages } from 'shared-ui/providers/flash-messages';

@Component({
  selector: 'app-inspections-overview',
  templateUrl: './inspections-overview.component.html',
})
export class InspectionsOverviewComponent extends BaseListComponent<InspectionEntity> {
  canDelete = false;
  sortField = 'datetime';

  constructor(
    protected dataService: InspectionsService,
    protected dialogService: MatDialog,
    protected flashMessages: FlashMessages
  ) {
    super(dataService, dialogService, flashMessages);
  }

  getEntityClass() {
    return InspectionEntity;
  }

  getFieldsOmit() {
    return ['_id', 'createdAt', 'updatedAt', 'customer.address'];
  }

  onGridFieldsCreate(fields: Record<string, GridField>) {
    fields.status.type = 'select';
    fields.status.options = this.dataService.getStatusOptions();
    fields.status.translate = true;
    fields.boilerType.type = 'select';
    fields.boilerType.options = this.dataService.getTypeOptions();
  }
}
