import { AddressEntity } from '@shared/domain/common/address.entity';
import { BaseEntity } from '@shared/entity/base-entity';
import { EntityField } from '@shared/entity/entity-field';
import { Field } from '@shared/model/field';
import { IsEmail } from 'class-validator';

export class CustomerEntity extends BaseEntity {
  @Field(true, String, [IsEmail()])
  email!: string;

  @Field(true, String)
  phone!: string;

  @Field(true, String)
  firstname!: string;

  @Field(true, String)
  lastname!: string;

  @Field(false, String)
  companyId?: string;

  @Field(false, String)
  companyVat?: string;

  @Field(false, String)
  companyName?: string;

  @EntityField(true, AddressEntity)
  billingAddress!: AddressEntity;

  @EntityField(true, AddressEntity)
  deliveryAddress!: AddressEntity;
}
