import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'admin/app/core/providers/auth.guard';
import { CategoriesDetailComponent } from 'admin/app/views/catalog/components/categories/detail/detail.component';
import { CategoriesNewComponent } from 'admin/app/views/catalog/components/categories/new/new.component';
import { CategoriesOverviewComponent } from 'admin/app/views/catalog/components/categories/overview/overview.component';
import { ManufacturersDetailComponent } from 'admin/app/views/catalog/components/manufacturers-detail/manufacturers-detail.component';
import { ManufacturersListComponent } from 'admin/app/views/catalog/components/manufacturers-list/manufacturers-list.component';
import { ProductsListComponent } from 'admin/app/views/catalog/components/products-list/products-list.component';
import { ProductsNewComponent } from 'admin/app/views/catalog/components/products/new/new.component';
import { ProductDetailComponent } from 'admin/app/views/catalog/components/products/product-detail/product-detail.component';

const routes: Routes = [
  {
    path: 'catalog/categories',
    canActivate: [AuthGuard],
    children: [
      { path: '', component: CategoriesOverviewComponent },
      { path: 'new', component: CategoriesNewComponent },
      { path: ':id', component: CategoriesDetailComponent },
    ],
  },
  {
    path: 'catalog/products',
    canActivate: [AuthGuard],
    children: [
      { path: '', component: ProductsListComponent },
      { path: 'new', component: ProductsNewComponent },
      { path: ':id', component: ProductDetailComponent },
    ],
  },
  {
    path: 'catalog/manufacturers',
    canActivate: [AuthGuard],
    children: [
      { path: '', component: ManufacturersListComponent },
      { path: ':id', component: ManufacturersDetailComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CatalogRoutingModule {}
