<mat-card class="card-filter">
  <!-- <shared-datagrid-filter-btn
    [fields]="fields"
    [filterName]="filterName"
    (changes)="filter.patchValue($event)"></shared-datagrid-filter-btn> -->
  <shared-datagrid-filter
    [fields]="fields"
    [filterName]="filterName"
    (changes)="filter.patchValue($event)"></shared-datagrid-filter>
</mat-card>

<mat-card class="p-0 pb-1">
  <mat-progress-bar *ngIf="isLoading" mode="indeterminate" color="primary"></mat-progress-bar>

  <div class="admin-table" [ngStyle]="{ height }">
    <table
      class="mat-table"
      matSort
      [matSortActive]="sortField"
      [matSortDirection]="sortDirection"
      [matSortDisableClear]="true">
      <thead>
        <tr>
          <th *ngFor="let field of fields">
            <span *ngIf="field.sort" [mat-sort-header]="field.key">{{ field.label | t }}</span>
            <span *ngIf="!field.sort">{{ field.label | t }}</span>
          </th>
        </tr>
      </thead>
      <tbody *ngIf="datasource">
        <tr *ngFor="let item of datasource.data | async">
          <td *ngFor="let field of fields; let i = index">
            <ng-container
              *ngIf="field.templateRef; else cell"
              [ngTemplateOutlet]="templates[field.templateRef]"
              [ngTemplateOutletContext]="{ item, field }"></ng-container>
            <ng-template #cell>
              <a *ngIf="i === 0" [routerLink]="item['_id']">{{ item | fieldValue: field }}</a>
              <span *ngIf="i !== 0">{{ item | fieldValue: field }}</span>
            </ng-template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" [pageSize]="25" showFirstLastButtons></mat-paginator>
</mat-card>
