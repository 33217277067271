import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthForgotComponent } from 'admin/app/core/components/auth-forgot/auth-forgot.component';
import { AuthResetComponent } from 'admin/app/core/components/auth-reset/auth-reset.component';
import { HomepageComponent } from 'admin/app/core/components/homepage/homepage.component';
import { LoginComponent } from 'admin/app/core/components/login/login.component';
import { UserProfileComponent } from 'admin/app/core/components/user-profile/user-profile.component';
import { AuthGuard } from 'admin/app/core/providers/auth.guard';
import { NotFoundComponent } from 'shared-ui/components/not-found/not-found.component';

const routes: Routes = [
  { path: 'auth/forgot', component: AuthForgotComponent },
  { path: 'auth/login', component: LoginComponent },
  { path: 'auth/reset', component: AuthResetComponent },
  {
    path: 'user/profile',
    canActivate: [AuthGuard],
    component: UserProfileComponent,
  },
  { path: '', canActivate: [AuthGuard], component: HomepageComponent },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AdminAppRoutingModule {}
