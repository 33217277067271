import { Component, signal } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ProductEntity } from '@shared/domain/products/product.entity';
import { BaseComponent } from 'shared-ui/components/base-component';
import { ProductsService } from 'shared-ui/domain/products/products.service';
import { Find } from 'shared-ui/models/find';
import { FlashMessages } from 'shared-ui/providers/flash-messages';
import { FormlyBuilder } from 'shared-ui/providers/formly-builder';

type MassEditForm = { change: number };
type MassChange = ProductEntity & { change: number };

@Component({
  selector: 'admin-products-mass-edit',
  templateUrl: './products-mass-edit.component.html',
})
export class ProductsMassEditComponent extends BaseComponent {
  form = signal(this.formlyBuilder.createForm<MassEditForm>());
  formFields = signal(this.getFormFields());
  data = signal<MassChange[]>([]);
  isLoading = signal(true);

  constructor(
    public dialog: MatDialogRef<ProductsMassEditComponent>,
    private dataService: ProductsService,
    private flashMessages: FlashMessages,
    private formlyBuilder: FormlyBuilder
  ) {
    super();
    this.subscribe(this.form().valueChanges, () => {
      const items = this.mapItems(this.data());
      this.data.set(items);
    });
  }

  loadItems(filter: Find['filter']) {
    const find: Find<ProductEntity> = {
      filter,
      sort: { active: 'name', direction: 'asc' },
      projection: ['name', 'price'],
    };
    this.subscribe(this.dataService.find(find), result => {
      this.data.set(this.mapItems(result.items));
      this.isLoading.set(false);
    });
  }

  confirm() {
    this.isLoading.set(true);
    const ids = this.data().map(item => item._id);
    const value = this.getChange();
    this.subscribe(this.dataService.updateMany(ids, 'price', value), result => {
      this.isLoading.set(false);
      this.flashMessages.show('All items were updated.');
      this.dialog.close(result);
    });
  }

  private getChange() {
    const percent = this.form().value.change ?? 0;
    return 1 + percent / 100;
  }

  private mapItems(items: ProductEntity[]) {
    const change = this.getChange();
    return items.map(item => Object.assign(item, { change: Math.round(item.price * change) }));
  }

  private getFormFields() {
    return this.formlyBuilder.create([
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            key: 'change',
            type: 'number',
            className: 'col-6',
            props: { label: 'Change (%)', required: true },
          },
        ],
      },
    ]);
  }
}
