import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FORMLY_CONFIG, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { AdminAppRoutingModule } from 'admin/app/admin-app-routing.module';
import { AdminAppComponent } from 'admin/app/admin-app.component';
import { AdminArticlesModule } from 'admin/app/articles/admin-articles.module';
import { AdminCoreModule } from 'admin/app/core/admin-core.module';
import { AdminInspectionsModule } from 'admin/app/inspections/admin-inspections.module';
import { AdminOrdersModule } from 'admin/app/orders/admin-orders.module';
import { AdminUsersModule } from 'admin/app/users/admin-users.module';
import { AdminCatalogModule } from 'admin/app/views/catalog/admin-catalog.module';
import { environment } from 'admin/environments/environment';
import { QuillModule } from 'ngx-quill';
import { createEnvProvider } from 'shared-ui/di/environment';
import { AppErrorHandler } from 'shared-ui/providers/app-error-handler';
import { AppLogger } from 'shared-ui/providers/app-logger';
import { BaseInterceptor } from 'shared-ui/providers/base-interceptor';
import { formlyConfig } from 'shared-ui/providers/formly-config';
import { getLanguage, getTranslations, TRANSLATIONS } from 'shared-ui/providers/locales';
import { Translator } from 'shared-ui/providers/translator';
import { SharedUiModule } from 'shared-ui/shared-ui.module';

@NgModule({
  bootstrap: [AdminAppComponent],
  declarations: [AdminAppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormlyModule.forRoot(),
    FormlyMaterialModule,
    FormlyMatDatepickerModule,
    QuillModule.forRoot(),
    AdminArticlesModule,
    AdminCatalogModule,
    AdminCoreModule,
    AdminInspectionsModule,
    AdminOrdersModule,
    AdminUsersModule,
    SharedUiModule,
    AdminAppRoutingModule,
  ],
  providers: [
    createEnvProvider(environment),
    AppLogger,
    { provide: LOCALE_ID, useFactory: getLanguage },
    { provide: TRANSLATIONS, useFactory: getTranslations },
    {
      provide: FORMLY_CONFIG,
      useFactory: formlyConfig,
      deps: [Translator],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: BaseInterceptor, multi: true },
    { provide: ErrorHandler, useClass: AppErrorHandler },
  ],
})
export class AdminAppModule {}
