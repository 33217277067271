import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { FormErrors } from 'shared-ui/providers/form-errors';

@Pipe({
  name: 'formError',
})
export class FormErrorPipe implements PipeTransform {
  constructor(private formErrors: FormErrors) {}

  transform(value: ValidationErrors | null | undefined): string {
    return this.formErrors.getError(value ?? null);
  }
}
