<div class="lock">
  <div class="lock-content">
    <mat-card>
      <mat-card-content>
        <h1>ADMIN</h1>
        <h4>
          {{ 'Enter your email address below and we will send you password reset instructions.' | t }}
        </h4>
        <div class="toast toast-error" *ngIf="showError">
          {{ 'Email was not found!' | t }}
        </div>
        <div class="toast toast-success" *ngIf="showSuccess">
          {{ 'Email with instructions for password reset was send to you!' | t }}
        </div>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <formly-form [form]="form" [fields]="fields" [options]="formOptions"></formly-form>
          <app-form-buttons
            [isLoading]="isLoading"
            label="Reset"
            color="primary"
            btnClass="btn-block"></app-form-buttons>
        </form>
        <div class="lock-container">
          <a routerLink="/auth/login" mat-button color="primary">{{ 'Back to Login' | t }}</a>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
