import { Component } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'shared-formly-repeat',
  template: `
    <div *ngFor="let field of field.fieldGroup; let i = index" class="row">
      <formly-field class="col repeat-col" [field]="field"></formly-field>
      <div class="col-auto d-flex align-items-center">
        <button type="button" mat-stroked-button color="warn" (click)="remove(i)">
          <icon icon="delete_outline"></icon>
        </button>
      </div>
    </div>
    <div class="my-3">
      <button type="button" mat-raised-button color="primary" (click)="add()">
        <icon icon="add_box"></icon>
      </button>
    </div>
  `,
  styleUrls: ['./formly-repeat.component.scss'],
})
export class FormlyRepeatComponent extends FieldArrayType {}
