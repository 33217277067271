import { Component, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OrderStateEnum } from '@shared/domain/orders/order-state';
import { OrderEntity } from '@shared/domain/orders/order.entity';
import { BaseComponent } from 'shared-ui/components/base-component';
import { OrdersService } from 'shared-ui/domain/orders/orders.service';
import { FlashMessages } from 'shared-ui/providers/flash-messages';
import { AppValidators } from 'shared-ui/validators/app-validators';

@Component({
  selector: 'app-orders-edit',
  templateUrl: './orders-edit.component.html',
  styleUrls: ['./orders-edit.component.scss'],
})
export class OrdersEditComponent extends BaseComponent {
  isLoading = false;
  readonly states = Object.entries(OrderStateEnum);
  formDelivery = this.fb.nonNullable.group({ price: [0] });
  form = this.fb.nonNullable.group({
    state: [OrderStateEnum.Created, [AppValidators.required]],
    delivery: this.formDelivery,
  });

  constructor(
    public dialog: MatDialogRef<OrdersEditComponent>,
    @Inject(MAT_DIALOG_DATA) public order: OrderEntity,
    private flashMessages: FlashMessages,
    private fb: FormBuilder,
    private dataService: OrdersService
  ) {
    super();
    this.form.patchValue(order);
  }

  close() {
    this.dialog.close();
  }

  onSubmit() {
    if (this.form.valid) {
      this.isLoading = true;
      this.subscribe(this.dataService.updateFromData(this.order._id, this.form.getRawValue()), () => {
        this.isLoading = false;
        this.flashMessages.show('Order was successfully updated.');
        this.dialog.close(true);
      });
    }
  }
}
