import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { FormlyModule } from '@ngx-formly/core';
import { AuthGuard } from 'admin/app/core/providers/auth.guard';
import { UsersDetailComponent } from 'admin/app/users/components/users-detail/users-detail.component';
import { UsersListComponent } from 'admin/app/users/components/users-list/users-list.component';
import { AdminUsersService } from 'shared-ui/domain/users/admin-users.service';
import { SharedUiModule } from 'shared-ui/shared-ui.module';

const routes: Routes = [
  {
    path: 'users',
    canActivate: [AuthGuard],
    children: [
      { path: '', component: UsersListComponent },
      { path: ':id', component: UsersDetailComponent },
    ],
  },
];

@NgModule({
  declarations: [UsersDetailComponent, UsersListComponent],
  imports: [
    CommonModule,
    FormlyModule,
    HttpClientModule,
    ReactiveFormsModule,
    SharedUiModule,
    RouterModule.forChild(routes),
  ],
  providers: [AdminUsersService],
})
export class AdminUsersModule {}
