<h2 mat-dialog-title>{{ 'Mass Editing' | t }}</h2>
<mat-dialog-content class="pt-2">
  <formly-form [form]="form()" [fields]="formFields()" />

  <h4 class="mt-0">{{ 'Items' | t }}</h4>

  <mat-card class="p-0 pb-1">
    @if (isLoading()) {
      <mat-progress-bar mode="indeterminate" color="primary" />
    }
    <div class="admin-table" [ngStyle]="{ height: '300px' }">
      <table class="mat-table">
        <thead>
          <tr>
            <th>{{ 'Name' | t }}</th>
            <th>{{ 'Price' | t }}</th>
            <th>{{ 'New Price' | t }}</th>
          </tr>
        </thead>
        <tbody>
          @for (item of data(); track item) {
            <tr>
              <td>{{ item['name'] }}</td>
              <td>{{ item['price'] | localeCurrency }}</td>
              <td>
                @if (form().value.change) {
                  {{ item['change'] | localeCurrency }}
                }
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  </mat-card>

  <div class="mt-3">
    <button
      mat-raised-button
      color="primary"
      (click)="confirm()"
      [disabled]="!form().value.change || !data().length || isLoading()">
      {{ 'Confirm' | t }}
    </button>
    <button mat-button color="primary" class="ml-2" (click)="dialog.close()">{{ 'Cancel' | t }}</button>
  </div>
</mat-dialog-content>
