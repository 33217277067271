<nav class="header">
  <button mat-flat-button color="primary" (click)="menuToggle.emit()" class="toggleBtn">
    <icon icon="menu"></icon>
  </button>

  <a href="/" mat-flat-button color="primary">ADMIN</a>

  <div class="flex-spacer"></div>

  <button mat-flat-button color="primary" [matMenuTriggerFor]="userMenu">
    <icon icon="account_circle"></icon>
    &nbsp;
    <span>{{ userName }}</span>
    &nbsp;
    <icon icon="arrow_drop_down"></icon>
  </button>
  <mat-menu #userMenu="matMenu">
    <button mat-menu-item routerLink="/user/profile">
      {{ 'Profile' | t }}
    </button>
    <button mat-menu-item (click)="logout()">{{ 'Log Out' | t }}</button>
  </mat-menu>
</nav>
