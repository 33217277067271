<div class="action-bar action-bar-full-w">
  <a routerLink="/articles" mat-icon-button color="primary" [matTooltip]="'Back to Overview' | t">
    <icon icon="arrow_back"></icon>
  </a>
  <span class="action-bar-title">{{ 'Articles' | t }} - {{ (isCreate ? 'Add' : 'Detail') | t }}</span>
</div>

<div class="mx-auto col-lg-6">
  <mat-progress-bar *ngIf="isLoading" mode="indeterminate" color="primary"></mat-progress-bar>
  <mat-card *ngIf="isCreate || item">
    <mat-card-content>
      <form [formGroup]="form" (ngSubmit)="save()" class="mat-form">
        <formly-form [fields]="formFields" [form]="form" [model]="item"></formly-form>
        <app-form-buttons [isLoading]="isLoading" label="Save" color="primary"></app-form-buttons>
      </form>
    </mat-card-content>
  </mat-card>
</div>
