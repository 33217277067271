import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { FormlyModule } from '@ngx-formly/core';
import { ArticlesDetailComponent } from 'admin/app/articles/components/articles/detail/articles-detail.component';
import { ArticlesOverviewComponent } from 'admin/app/articles/components/articles/overview/articles-overview.component';
import { AuthGuard } from 'admin/app/core/providers/auth.guard';
import { ArticlesService } from 'shared-ui/domain/articles/articles.service';
import { SharedUiModule } from 'shared-ui/shared-ui.module';

const routes: Routes = [
  {
    path: 'articles',
    canActivate: [AuthGuard],
    children: [
      { path: '', component: ArticlesOverviewComponent },
      { path: ':id', component: ArticlesDetailComponent },
    ],
  },
];

@NgModule({
  declarations: [ArticlesOverviewComponent, ArticlesDetailComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    FormlyModule,
    ReactiveFormsModule,
    SharedUiModule,
    RouterModule.forChild(routes),
  ],
  providers: [ArticlesService],
})
export class AdminArticlesModule {}
