import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { CategoryEntity } from '@shared/domain/products/category.entity';
import { orderBy } from 'lodash';
import { map } from 'rxjs/operators';
import { CategoriesService } from 'shared-ui/domain/products/categories.service';
import { FormlyBuilder } from 'shared-ui/providers/formly-builder';

type CategoryFormFields = Pick<
  CategoryEntity,
  'name' | 'fullName' | 'parentId' | 'position' | 'url' | 'keywords' | 'description'
>;

@Component({
  selector: 'app-catalog-categories-form',
  templateUrl: './form.component.html',
})
export class CategoriesFormComponent implements OnInit {
  @Input()
  category!: CategoryEntity;
  @Output()
  submitted = new EventEmitter<CategoryFormFields>();
  categories: CategoryEntity[] = [];
  form = new UntypedFormGroup({});
  formFields: FormlyFieldConfig[] = [];
  isLoading = false;

  constructor(
    private categoriesService: CategoriesService,
    private formly: FormlyBuilder
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.categoriesService.getCategoriesWithPaths().subscribe(value => {
      this.categories = value;
      this.form.patchValue(this.category, { emitEvent: false });
    });
  }

  onSubmit(): void {
    this.formly.processForm<CategoryFormFields>(this.form).subscribe(values => {
      this.submitted.emit(values);
    });
  }

  private getCategories() {
    return this.categoriesService.getCategoriesWithPaths().pipe(
      map(items => {
        return [{}, ...orderBy(items, 'path')];
      })
    );
  }

  protected createForm() {
    const fields: FormlyFieldConfig[] = [
      {
        key: 'name',
        type: 'input',
        props: { label: 'Name', required: true },
      },
      {
        key: 'fullName',
        type: 'input',
        props: { label: 'Full Title', required: true },
      },
      {
        key: 'parentId',
        type: 'select',
        props: {
          label: 'Parent Category',
          options: this.getCategories(),
          valueProp: '_id',
          labelProp: 'path',
        },
      },
      {
        key: 'position',
        type: 'input',
        props: { label: 'Position', type: 'number' },
      },
      {
        key: 'url',
        type: 'input',
        props: { label: 'URL' },
      },
      {
        key: 'keywords',
        type: 'input',
        props: { label: 'Keywords' },
      },
      {
        key: 'description',
        type: 'textarea',
        props: { label: 'Description', rows: 4 },
      },
    ];
    this.formFields = this.formly.create(fields);
  }
}
