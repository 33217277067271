import { InspectionBoilerType } from '@shared/domain/inspections/inspection-boiler-type';
import { InspectionCustomerEntity } from '@shared/domain/inspections/inspection-customer.entity';
import { InspectionStatus } from '@shared/domain/inspections/inspection-status';
import { BaseRootEntity } from '@shared/entity/base-root-entity';
import { EntityField } from '@shared/entity/entity-field';
import { Field } from '@shared/model/field';
import { IsEnum } from 'class-validator';

export class InspectionEntity extends BaseRootEntity {
  @Field(true, Date)
  datetime!: Date;

  @Field(true, String, [IsEnum(InspectionStatus)])
  status!: InspectionStatus;

  @Field(true, String, [IsEnum(InspectionBoilerType)])
  boilerType!: InspectionBoilerType;

  @EntityField(true, InspectionCustomerEntity)
  customer!: InspectionCustomerEntity;
}
