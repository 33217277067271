<h2 class="mat-dialog-title">{{ 'Edit order' | t }}</h2>
<form [formGroup]="form" (ngSubmit)="onSubmit()" class="mat-form">
  <mat-form-field appearance="fill">
    <mat-label>{{ 'State' | t }}</mat-label>
    <mat-select formControlName="state" required>
      <mat-option *ngFor="let state of states" [value]="state[1]" [disabled]="state[1] < order.state">
        {{ state[0] | t }}
      </mat-option>
    </mat-select>
    <mat-error>{{ form.controls.state.errors | formError }}</mat-error>
  </mat-form-field>
  <div formGroupName="delivery">
    <mat-form-field appearance="fill">
      <mat-label>{{ 'Shipping cost' | t }}</mat-label>
      <input matInput formControlName="price" type="number" required min="0" />
      <mat-error>{{ formDelivery.controls.price.errors | formError }}</mat-error>
    </mat-form-field>
  </div>
  <app-form-buttons [isLoading]="isLoading" [showCancel]="true" (cancel)="close()"></app-form-buttons>
</form>
