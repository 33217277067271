import { Country } from '@shared/domain/common/country';
import { BaseEntity } from '@shared/entity/base-entity';
import { Field } from '@shared/model/field';
import { IsEnum } from 'class-validator';

export class DeliveryEntity extends BaseEntity {
  @Field(true, String)
  id!: string;

  @Field(true, String)
  title!: string;

  @Field(true, Number)
  price!: number;

  @Field(true, String, [IsEnum(Country)])
  country!: Country;
}
