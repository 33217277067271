<div class="row">
  <div class="col-lg-6">
    <h3>{{ 'List of Accessories' | t }}</h3>
    <ul class="list-group">
      <li *ngFor="let acc of accessories" class="list-group-item">
        <b>{{ getProductName(acc) }}</b>
        <button (click)="removeAcc(acc)" type="button" mat-button color="warn">
          <icon icon="clear"></icon>
        </button>
      </li>
    </ul>
  </div>
  <div class="col-lg-6">
    <h3>{{ 'Available products' | t }}</h3>
    <mat-form-field appearance="outline">
      <input #filter matInput (input)="setFilter(filter.value)" placeholder="Filter..." autocomplete="off" />
    </mat-form-field>
    <mat-selection-list class="available-list">
      <ng-container *ngFor="let item of availableProducts">
        <mat-option (click)="addAcc(item._id)">{{ item.name }}</mat-option>
      </ng-container>
    </mat-selection-list>
  </div>
</div>

<div class="row no-gutters">
  <button mat-raised-button color="primary" (click)="save()" [disabled]="isSaving">{{ 'Save' | t }}</button>
  <button mat-button color="primary" class="ml-3" (click)="close()" [disabled]="isSaving">{{ 'Cancel' | t }}</button>
</div>
