import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UserPoolsEnum } from '@shared/domain/users/user-pools.enum';
import { UserEntity } from '@shared/domain/users/user.entity';
import { merge } from 'lodash';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Find } from 'shared-ui/models/find';
import { CrudHttpService } from 'shared-ui/providers/crud-http-service';

@Injectable()
export abstract class UsersService extends CrudHttpService<UserEntity> {
  protected baseUrl = 'users';
  protected entityClass = UserEntity;

  abstract get poolId(): UserPoolsEnum;

  find(find: Find = {}): Observable<{ items: UserEntity[]; total: number }> {
    find.filter = merge(find.filter ?? {}, { poolId: this.poolId });
    return super.find(find);
  }

  create(body: Partial<UserEntity>): Observable<UserEntity | null> {
    body.poolId = this.poolId;
    body.roles = ['admin'];
    return super.create(body);
  }

  update(id: string, body: Partial<UserEntity>): Observable<UserEntity | null> {
    body.poolId = this.poolId;
    return super.update(id, body);
  }

  getByEmail(email: string): Observable<UserEntity | undefined> {
    const query: Find<UserEntity> = {
      filter: { email, poolId: this.poolId },
    };
    return super.find(query).pipe(map(users => users.items[0]));
  }

  createUniqueEmailValidator() {
    return {
      expression: (control: FormControl) => {
        if (!control.dirty) {
          return of(true);
        }
        return this.getByEmail(control.value).pipe(map(user => !user));
      },
      message: this.translator.translate('This email is already used'),
    };
  }
}
