import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthService } from 'admin/app/core/providers/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Output() menuToggle = new EventEmitter();
  userName: string;

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.userName = this.authService.userName;
  }

  logout(): Promise<boolean> {
    return this.authService.logout();
  }
}
