<div class="action-bar action-bar-full-w">
  <span class="action-bar-title">{{ 'Categories' | t }}</span>
  <a mat-button color="primary" routerLink="new">
    <icon icon="add_box"></icon><span class="label">{{ 'Add' | t }}</span>
  </a>
</div>

<div class="right-float">
  <button mat-raised-button (click)="toggleAll()">
    <span *ngIf="expanded">{{ 'Collapse All' | t }}</span>
    <span *ngIf="!expanded">{{ 'Expand All' | t }}</span>
  </button>
</div>

<mat-tree [dataSource]="nestedDataSource" [treeControl]="nestedTreeControl" class="example-tree mat-elevation-z3">
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
    <li class="mat-tree-node">
      <button mat-icon-button disabled></button>
      <a [routerLink]="node._id">{{ node.name }}</a>
    </li>
  </mat-tree-node>
  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasNestedChild">
    <li>
      <div class="mat-tree-node">
        <button mat-icon-button matTreeNodeToggle>
          <icon [icon]="nestedTreeControl.isExpanded(node) ? 'arrow_drop_down' : 'arrow_right'"></icon>
        </button>
        <a [routerLink]="node._id">{{ node.name }}</a>
      </div>
      <ul [class.example-tree-invisible]="!nestedTreeControl.isExpanded(node)">
        <ng-container matTreeNodeOutlet></ng-container>
      </ul>
    </li>
  </mat-nested-tree-node>
</mat-tree>
