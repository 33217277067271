export const OrderStateEnum = {
  Created: 1,
  Accepted: 2,
  Paid: 3,
  Expeded: 4,
  Completed: 5,
  Canceled: 10,
};

export type OrderState = (typeof OrderStateEnum)[keyof typeof OrderStateEnum];
