<div class="action-bar action-bar-full-w">
  <span class="action-bar-title">{{ 'User Profile' | t }}</span>
</div>

<div class="container-xl col-xl-6">
  <mat-card class="mb-3">
    <mat-card-header>
      <mat-card-title>{{ 'Change Email' | t }}</mat-card-title>
      <mat-card-subtitle>&nbsp;</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="formEmail" (ngSubmit)="onSubmitFormEmail()" class="mat-form">
        <formly-form [form]="formEmail" [fields]="fieldsEmail" [model]="user"></formly-form>
        <app-form-buttons [isLoading]="isSavingEmail"></app-form-buttons>
      </form>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-header>
      <mat-card-title>{{ 'Change Password' | t }}</mat-card-title>
      <mat-card-subtitle>&nbsp;</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="formPassword" (ngSubmit)="onSubmitFormPassword()" class="mat-form">
        <formly-form [form]="formPassword" [fields]="fieldsPassword"></formly-form>
        <app-form-buttons [isLoading]="isSavingPassword"></app-form-buttons>
      </form>
    </mat-card-content>
  </mat-card>
</div>
