import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';

export class AppValidators extends Validators {
  static number(requiredIf = true): ValidatorFn {
    return (control: AbstractControl) => {
      if (requiredIf === false && !control.value) {
        return null;
      }

      const pattern = new RegExp(/^[0-9.]*$/);
      const validationError = { number: { value: control.value } };

      return pattern.test(control.value) ? null : validationError;
    };
  }

  static true(): ValidatorFn {
    return (control: AbstractControl) => {
      const error = { true: { value: control.value } };
      return control.value === true ? null : error;
    };
  }
}
