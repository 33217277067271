<div class="nav-sidebar" [class.opened]="menuOpened">
  <div class="nav-sidebar-inner-scroll">
    <nav class="sidebar-top-level-items">
      <a routerLink="" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <icon icon="dashboard"></icon>
        <span> {{ 'Dashboard' | t }}</span>
      </a>
      <a routerLink="/orders" routerLinkActive="active">
        <icon icon="shopping_cart"></icon>
        <span> {{ 'Orders' | t }}</span>
      </a>
      <a routerLink="/catalog/products" routerLinkActive="active">
        <icon icon="list_alt"></icon>
        <span> {{ 'Products' | t }}</span>
      </a>
      <a routerLink="/catalog/categories" routerLinkActive="active">
        <icon icon="category"></icon>
        <span> {{ 'Categories' | t }}</span>
      </a>
      <a routerLink="/catalog/manufacturers" routerLinkActive="active">
        <icon icon="factory"></icon>
        <span> {{ 'Manufacturers' | t }}</span>
      </a>
      <a routerLink="/articles" routerLinkActive="active">
        <icon icon="file_copy"></icon>
        <span> {{ 'Articles' | t }}</span>
      </a>
      <a routerLink="/inspections" routerLinkActive="active">
        <icon icon="home_repair_service"></icon>
        <span> {{ 'Boilers Inspections' | t }}</span>
      </a>
      <a routerLink="/users" routerLinkActive="active">
        <icon icon="people"></icon>
        <span> {{ 'Users' | t }}</span>
      </a>
    </nav>
  </div>
</div>
