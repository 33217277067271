import 'reflect-metadata';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AdminAppModule } from 'admin/app/admin-app.module';
import { environment } from 'admin/environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AdminAppModule)
  .catch(err => console.error(err));
