<app-header *ngIf="isLoggedIn" (menuToggle)="menuOpened = !menuOpened"></app-header>

<div [class.layout-page]="isLoggedIn" [class.menu-opened]="menuOpened">
  <app-menu *ngIf="isLoggedIn" [menuOpened]="menuOpened"></app-menu>
  <div *ngIf="menuOpened" class="sidebar-backdrop" (click)="menuOpened = false"></div>

  <div [class.content-wrapper]="isLoggedIn">
    <router-outlet></router-outlet>
  </div>
</div>

<shared-page-loader></shared-page-loader>
