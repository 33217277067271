<div [class.text-center]="centered">
  <button type="submit" mat-raised-button [color]="color" [disabled]="isLoading" [ngClass]="btnClass" [ngStyle]="btnStyle">
    <span *ngIf="!isLoading">{{ label | t }}</span>
    <mat-progress-spinner
      *ngIf="isLoading"
      mode="indeterminate"
      [diameter]="36"
      style="margin: auto"
    ></mat-progress-spinner>
  </button>
  <button *ngIf="showCancel" type="button" mat-stroked-button class="ml-3" (click)="cancel.emit()">{{ 'Cancel' | t }}</button>
</div>
