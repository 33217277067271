import { OrderState, OrderStateEnum } from '@shared/domain/orders/order-state';
import { BaseEntity } from '@shared/entity/base-entity';
import { Field } from '@shared/model/field';
import { IsIn } from 'class-validator';

export class OrderHistoryEntity extends BaseEntity {
  @Field(true, Number, [IsIn(Object.values(OrderStateEnum))])
  state: OrderState = OrderStateEnum.Created;

  @Field(true, Date)
  date = new Date();

  @Field(false, String)
  user?: string;
}
