<div class="action-bar action-bar-full-w">
  <a routerLink="/catalog/products" mat-icon-button color="primary" [matTooltip]="'Back to Overview' | t">
    <icon icon="arrow_back"></icon>
  </a>
  <span class="action-bar-title">{{ 'Products' | t }} - {{ 'New' | t }}</span>
</div>

<mat-card class="col-lg-6 m-auto">
  <mat-card-content>
    <form [formGroup]="form" (ngSubmit)="onSubmit()" class="mat-form">
      <formly-form [fields]="formFields" [form]="form"></formly-form>
      <app-form-buttons [isLoading]="isLoading" label="Save" color="primary"></app-form-buttons>
    </form>
  </mat-card-content>
</mat-card>
