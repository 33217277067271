import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SortDirection } from '@angular/material/sort';
import { ArticleEntity } from '@shared/domain/articles/article.entity';
import { BaseListComponent } from 'shared-ui/components/base-list-component';
import { ArticlesService } from 'shared-ui/domain/articles/articles.service';
import { GridField } from 'shared-ui/models/grid-field';
import { FlashMessages } from 'shared-ui/providers/flash-messages';

@Component({
  selector: 'app-articles-overview',
  templateUrl: './articles-overview.component.html',
})
export class ArticlesOverviewComponent extends BaseListComponent<ArticleEntity> {
  sortField = 'title';
  sortDirection: SortDirection = 'asc';

  constructor(
    protected dataService: ArticlesService,
    protected dialogService: MatDialog,
    protected flashMessages: FlashMessages
  ) {
    super(dataService, dialogService, flashMessages);
  }

  getEntityClass() {
    return ArticleEntity;
  }

  getFieldsOmit() {
    return ['_id', 'createdAt', 'updatedAt', 'content'];
  }

  onGridFieldsCreate(fields: Record<string, GridField>) {
    fields.category.type = 'select';
    fields.category.options = this.dataService.getCategoriesOptions();
  }
}
