import { Directive, ElementRef, Optional, Self } from '@angular/core';
import { FormGroupDirective, NgControl } from '@angular/forms';

let uniqueId = 0;

@Directive({
  selector: 'input[formInput], textarea[formInput], select[formInput]',
  exportAs: 'formInput',
})
export class AppFormInputDirective {
  protected _uniqueId = `form-input-${uniqueId++}`;

  constructor(
    protected _elementRef: ElementRef<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
    @Optional() @Self() protected _ngControl: NgControl,
    @Optional() protected _parentFormGroup: FormGroupDirective
  ) {
    if (!this.element.id) this.element.id = this.id;
    if (!(this.element.type === 'checkbox' || this.element.type === 'radio')) {
      this.element.classList.add('form-control');
    }
  }

  get control(): NgControl {
    return this._ngControl;
  }

  get element(): HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement {
    return this._elementRef.nativeElement;
  }

  get form(): FormGroupDirective {
    return this._parentFormGroup;
  }

  get id(): string {
    return this._uniqueId;
  }
}
