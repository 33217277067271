import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SortDirection } from '@angular/material/sort';
import { ProductEntity } from '@shared/domain/products/product.entity';
import { ProductsMassEditComponent } from 'admin/app/views/catalog/components/products-mass-edit/products-mass-edit.component';
import { BaseListComponent } from 'shared-ui/components/base-list-component';
import { ManufacturersService } from 'shared-ui/domain/products/manufacturers.service';
import { ProductsService } from 'shared-ui/domain/products/products.service';
import { GridField } from 'shared-ui/models/grid-field';
import { FlashMessages } from 'shared-ui/providers/flash-messages';

@Component({
  selector: 'admin-products-list',
  templateUrl: './products-list.component.html',
})
export class ProductsListComponent extends BaseListComponent<ProductEntity> {
  sortField = 'name';
  sortDirection: SortDirection = 'asc';

  constructor(
    protected dataService: ProductsService,
    protected manufacturersService: ManufacturersService,
    protected dialogService: MatDialog,
    protected flashMessages: FlashMessages
  ) {
    super(dataService, dialogService, flashMessages);
  }

  getEntityClass() {
    return ProductEntity;
  }

  getFieldsPick(): string[] {
    return ['name', 'manufacturerName', 'price', 'categoryName'];
  }

  onGridFieldsCreate(fields: Record<keyof ProductEntity, GridField>) {
    fields.price.templateRef = 'price';
    fields.categoryName.filter = false;
    fields.manufacturerName.label = 'Manufacturer';
    fields.manufacturerName.type = 'select';
    this.manufacturersService.find({ sort: { active: 'name', direction: 'asc' } }).subscribe(result => {
      fields.manufacturerName.options = result.items.map(item => ({ label: item.name, value: item.name }));
    });
  }

  openMassEdit() {
    const dialogRef = this.dialogService.open(ProductsMassEditComponent, { disableClose: true, minWidth: 640 });
    const filter = this.datasource.filter;
    dialogRef.componentInstance.loadItems(filter?.value ?? {});
    this.subscribe(dialogRef.afterClosed(), result => {
      if (result) this.datasource.refresh();
    });
  }
}
