import { Component } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ChangeEmailRequestDto } from '@shared/domain/users/change-email-request.dto';
import { ChangePasswordRequestDto } from '@shared/domain/users/change-password-request.dto';
import { UserPayload } from '@shared/domain/users/user-payload';
import { Maybe } from '@shared/types';
import { AuthService } from 'admin/app/core/providers/auth.service';
import { AdminUsersService } from 'shared-ui/domain/users/admin-users.service';
import { FlashMessages } from 'shared-ui/providers/flash-messages';
import { FormlyBuilder } from 'shared-ui/providers/formly-builder';

@Component({
  selector: 'admin-user-profile',
  templateUrl: './user-profile.component.html',
})
export class UserProfileComponent {
  fieldsEmail: FormlyFieldConfig[] = [];
  fieldsPassword: FormlyFieldConfig[] = [];
  formEmail = this.formly.createForm<ChangeEmailRequestDto>();
  formPassword = this.formly.createForm<ChangePasswordRequestDto>();
  isSavingEmail = false;
  isSavingPassword = false;
  user: Maybe<UserPayload>;

  constructor(
    private authService: AuthService,
    private flashMessages: FlashMessages,
    private usersService: AdminUsersService,
    private formly: FormlyBuilder
  ) {
    this.createForms();
    this.loadData();
  }

  onSubmitFormEmail() {
    if (!this.formEmail.valid || !this.formEmail.dirty) {
      return;
    }
    this.isSavingEmail = true;
    this.authService.changeEmail(this.formly.getFormValue(this.formEmail)).subscribe(() => {
      this.isSavingEmail = false;
      this.flashMessages.show('E-mail was changed. You can login using new email.');
    });
  }

  onSubmitFormPassword() {
    if (!this.formPassword.valid || !this.formPassword.dirty) {
      return;
    }
    this.isSavingPassword = true;
    this.authService.changePassword(this.formly.getFormValue(this.formPassword)).subscribe(() => {
      this.isSavingPassword = false;
      this.flashMessages.show('Password was changed. You can login using new password.');
    });
  }

  private createForms() {
    this.fieldsEmail = [
      {
        key: 'email',
        type: 'input',
        validators: {
          validation: ['email'],
        },
        asyncValidators: {
          uniqueEmail: this.usersService.createUniqueEmailValidator(),
        },
        props: { label: 'Email', required: true },
      },
    ];
    this.fieldsPassword = [
      {
        key: 'password',
        type: 'input',
        validators: {},
        props: {
          label: 'Password',
          type: 'password',
          required: true,
          minLength: 8,
        },
      },
      {
        key: 'passwordRepeat',
        type: 'input',
        validators: {
          same: this.formly.createSameValidator(this.formPassword, 'password', 'Passwords do not match'),
        },
        props: {
          label: 'Password Repeat',
          type: 'password',
          required: true,
          minLength: 8,
        },
      },
    ];
    this.formly.create(this.fieldsEmail);
    this.formly.create(this.fieldsPassword);
  }

  private loadData() {
    this.user = this.authService.getUser();
  }
}
