import { OrderState, OrderStateEnum } from '@shared/domain/orders/order-state';
import { BaseRootEntity } from '@shared/entity/base-root-entity';
import { ArrayEntityField, EntityField } from '@shared/entity/entity-field';
import { Field } from '@shared/model/field';
import { CustomerEntity } from '@shared/domain/orders/customer.entity';
import { DeliveryEntity } from '@shared/domain/orders/delivery.entity';
import { OrderHistoryEntity } from '@shared/domain/orders/order-history.entity';
import { OrderProductEntity } from '@shared/domain/orders/order-product.entity';
import { PaymentEntity } from '@shared/domain/orders/payment.entity';
import { ArrayMinSize, IsIn } from 'class-validator';

export class OrderEntity extends BaseRootEntity {
  @Field(true, String)
  code!: string;

  @EntityField(true, CustomerEntity)
  customer!: CustomerEntity;

  @EntityField(true, DeliveryEntity)
  delivery!: DeliveryEntity;

  @EntityField(true, PaymentEntity)
  payment!: PaymentEntity;

  @ArrayEntityField(true, OrderProductEntity)
  @ArrayMinSize(1)
  products: OrderProductEntity[] = [];

  @Field(true, Number, [IsIn(Object.values(OrderStateEnum))])
  state: OrderState = OrderStateEnum.Created;

  @Field(true, Number)
  subtotal!: number;

  @Field(true, Number)
  total!: number;

  @ArrayEntityField(true, OrderHistoryEntity)
  history: OrderHistoryEntity[] = [];

  recalculate(): void {
    const deliveryCost = this.delivery?.price ?? 0;
    const paymentCost = this.payment?.price ?? 0;
    const productsCost = this.products.reduce((cost: number, product: OrderProductEntity) => {
      return cost + product.price * product.count * 1.21;
    }, 0);
    this.subtotal = productsCost;
    this.total = deliveryCost + paymentCost + productsCost;
  }
}
