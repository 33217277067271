import { UserPayload } from '@shared/domain/users/user-payload';
import { UserPoolsEnum } from '@shared/domain/users/user-pools.enum';
import { BaseRootEntity } from '@shared/entity/base-root-entity';
import { Field } from '@shared/model/field';
import { IsEmail, IsIn } from 'class-validator';

export class UserEntity extends BaseRootEntity {
  @Field(true, String, [IsIn(Object.values(UserPoolsEnum))])
  poolId!: UserPoolsEnum;

  @Field(true, String, [IsEmail()])
  email!: string;

  @Field(true, String)
  name!: string;

  @Field(true, [String])
  roles: string[] = [];

  @Field(true, String)
  hashedPassword!: string;

  @Field(true, Boolean)
  active = true;

  toPayload(): UserPayload {
    return {
      id: this._id,
      poolId: this.poolId,
      name: this.name,
      email: this.email,
      roles: this.roles,
      active: this.active,
    };
  }
}
