import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProductEntity } from '@shared/domain/products/product.entity';
import { ProductsService } from 'shared-ui/domain/products/products.service';
import { BaseComponent } from 'shared-ui/components/base-component';
import { DeleteDialogComponent } from 'shared-ui/components/delete-dialog/delete-dialog.component';
import { FlashMessages } from 'shared-ui/providers/flash-messages';
import { ProgressService } from 'shared-ui/providers/progress.service';
import { Translator } from 'shared-ui/providers/translator';

@Component({
  selector: 'admin-product-detail-files',
  templateUrl: './product-detail-files.component.html',
})
export class ProductDetailFilesComponent extends BaseComponent {
  fileList?: FileList;
  filesNames: string[] = [];
  @Input()
  product?: ProductEntity;
  @Output()
  readonly closed = new EventEmitter();

  constructor(
    private dataService: ProductsService,
    private dialogFactory: MatDialog,
    private flashMessages: FlashMessages,
    private progress: ProgressService,
    private translator: Translator
  ) {
    super();
    this.reset();
  }

  reset() {
    this.fileList = undefined;
    this.filesNames = [];
  }

  filesChange(input: HTMLInputElement) {
    if (input.files) {
      this.fileList = input.files;
      this.filesNames = Array.from(this.fileList).map(file => file.name);
    }
  }

  uploadFiles() {
    if (this.product && this.fileList?.length) {
      this.progress.pageLoadStart();
      this.subscribe(this.dataService.uploadFiles(this.fileList, this.product._id), {
        next: files => {
          if (this.product) this.product.files = files;
          this.reset();
          this.flashMessages.show('Files were successfully uploaded to server.');
        },
        error: () => this.flashMessages.show('Bad request'),
        complete: () => this.progress.pageLoadEnd(),
      });
    }
  }

  deleteFile(file: string) {
    const dialog = this.dialogFactory.open(DeleteDialogComponent, { autoFocus: false });
    dialog.componentInstance.label = file;
    dialog.afterClosed().subscribe(value => {
      if (value === true) this.onDeleteFile(file);
    });
  }

  private onDeleteFile(file: string) {
    if (this.product) {
      this.progress.pageLoadStart();
      this.subscribe(this.dataService.deleteFiles([file], this.product._id), {
        next: files => {
          if (this.product) this.product.files = files;
          const message = this.translator.translate('File "[0]" was removed.', [file]);
          this.flashMessages.show(message, '', {}, false);
        },
        error: () => this.flashMessages.show('Bad request'),
        complete: () => this.progress.pageLoadEnd(),
      });
    }
  }
}
