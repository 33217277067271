import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProductEntity } from '@shared/domain/products/product.entity';
import { ProductsService } from 'shared-ui/domain/products/products.service';
import { BaseComponent } from 'shared-ui/components/base-component';
import { DeleteDialogComponent } from 'shared-ui/components/delete-dialog/delete-dialog.component';
import { FlashMessages } from 'shared-ui/providers/flash-messages';
import { ProgressService } from 'shared-ui/providers/progress.service';
import { Translator } from 'shared-ui/providers/translator';

@Component({
  selector: 'admin-product-detail-images',
  templateUrl: './product-detail-images.component.html',
  styleUrls: ['./product-detail-images.component.scss'],
})
export class ProductDetailImagesComponent extends BaseComponent {
  defaultFile?: File;
  defaultFileName?: string;
  fileList?: FileList;
  filesNames: string[] = [];
  @Input()
  product?: ProductEntity;
  @Output()
  readonly closed = new EventEmitter();

  constructor(
    private dataService: ProductsService,
    private dialogFactory: MatDialog,
    private flashMessages: FlashMessages,
    private progress: ProgressService,
    private translator: Translator
  ) {
    super();
    this.resetDefault();
    this.reset();
  }

  resetDefault() {
    this.defaultFile = undefined;
    this.defaultFileName = undefined;
  }

  reset() {
    this.fileList = undefined;
    this.filesNames = [];
  }

  defaultFileChange(input: HTMLInputElement) {
    if (input.files?.[0]) {
      this.defaultFile = input.files[0];
      this.defaultFileName = this.defaultFile.name;
    }
  }

  filesChange(input: HTMLInputElement) {
    if (input.files) {
      this.fileList = input.files;
      this.filesNames = Array.from(this.fileList).map(file => file.name);
    }
  }

  uploadDefaultImage() {
    if (this.product && this.defaultFile) {
      this.progress.pageLoadStart();
      this.subscribe(this.dataService.uploadDefaultImage(this.defaultFile, this.product._id), {
        next: product => {
          if (this.product) this.product.defaultImage = product.defaultImage;
          this.resetDefault();
          this.flashMessages.show('Default image was successfully uploaded to server.');
        },
        error: () => this.flashMessages.show('Bad request'),
        complete: () => this.progress.pageLoadEnd(),
      });
    }
  }

  uploadImages() {
    if (this.product && this.fileList?.length) {
      this.progress.pageLoadStart();
      this.subscribe(this.dataService.uploadImages(this.fileList, this.product._id), {
        next: images => {
          if (this.product) this.product.images = images;
          this.reset();
          this.flashMessages.show('Images were successfully uploaded to server.');
        },
        error: () => this.flashMessages.show('Bad request'),
        complete: () => this.progress.pageLoadEnd(),
      });
    }
  }

  deleteImage(file: string) {
    const dialog = this.dialogFactory.open(DeleteDialogComponent, { autoFocus: false });
    dialog.componentInstance.label = file;
    dialog.afterClosed().subscribe(value => {
      if (value === true) this.onDeleteFile(file);
    });
  }

  private onDeleteFile(file: string) {
    if (this.product) {
      this.progress.pageLoadStart();
      this.subscribe(this.dataService.deleteImages([file], this.product._id), {
        next: images => {
          if (this.product) this.product.images = images;
          const message = this.translator.translate('Image "[0]" was removed.', [file]);
          this.flashMessages.show(message, '', {}, false);
        },
        error: () => this.flashMessages.show('Bad request'),
        complete: () => this.progress.pageLoadEnd(),
      });
    }
  }
}
