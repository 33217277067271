<div class="action-bar action-bar-full-w">
  <a routerLink="/catalog/categories" mat-icon-button color="primary" [matTooltip]="'Back to Overview' | t">
    <icon icon="arrow_back"></icon>
  </a>
  <span class="action-bar-title">{{ 'Categories' | t }} - {{ 'Add' | t }}</span>
</div>

<mat-card class="col-lg-6 m-auto">
  <mat-card-content>
    <app-catalog-categories-form (submitted)="onSubmit($event)"></app-catalog-categories-form>
  </mat-card-content>
</mat-card>
