import { Country } from '@shared/domain/common/country';
import { DeliverySettingsModel } from '@shared/domain/orders/delivery.model';
import { PaymentModel } from '@shared/domain/orders/payment.model';

export const COD_PAYMENT: PaymentModel = { id: 'COD', title: 'Dobírka', price: 50 };
export const LOCAL_PAYMENT: PaymentModel = { id: 'LOCAL', title: 'Při vyzvednutí', price: 0 };
export const BANK_DEPOSIT_PAYMENT: PaymentModel = { id: 'BANK_DEPOSIT', title: 'Bankovní převod', price: 0 };

export const payments: PaymentModel[] = [COD_PAYMENT, LOCAL_PAYMENT, BANK_DEPOSIT_PAYMENT];

export const deliveries: DeliverySettingsModel[] = [
  /* CZ */
  {
    id: 'OSOBNI_ODBER',
    title: 'Osobní odběr',
    price: 0,
    codPrice: 0,
    country: Country.CZ,
    description: 'Petra Bezruče 98, Chuchelná, 74724',
    payments: [LOCAL_PAYMENT, BANK_DEPOSIT_PAYMENT],
    weightTiers: {},
  },
  {
    id: 'DPD',
    heurekaId: 'DPD',
    title: 'DPD',
    price: 194,
    codPrice: 50,
    country: Country.CZ,
    payments: [COD_PAYMENT, BANK_DEPOSIT_PAYMENT],
    maxWeight: 50,
    weightTiers: {
      5: 228,
      10: 268,
      20: 294,
    },
  },
  {
    id: 'DPD_SK',
    title: 'DPD na Slovensko',
    price: 270,
    codPrice: 50,
    country: Country.SK,
    payments: [COD_PAYMENT, BANK_DEPOSIT_PAYMENT],
    maxWeight: 31.5,
    weightTiers: {
      10: 294,
      20: 312,
    },
  },
  {
    id: 'ZASILKOVNA_PICKUP',
    heurekaId: 'ZASILKOVNA',
    title: 'Zásilkovna - Výdejní místo a Z-BOX',
    isPickup: true,
    price: 78,
    codPrice: 21,
    country: Country.CZ,
    payments: [COD_PAYMENT, BANK_DEPOSIT_PAYMENT],
    maxWeight: 10,
    weightTiers: {
      5: 119,
    },
  },
  {
    id: 'FOFR',
    heurekaId: 'FOFR',
    title: 'FOFR',
    price: 2250,
    codPrice: 0,
    country: Country.CZ,
    payments: [BANK_DEPOSIT_PAYMENT],
    minWeight: 50,
    weightTiers: {
      100: 2945,
      250: 4061,
      500: 6252,
    },
  },
  {
    id: 'FOFR_SK',
    title: 'FOFR na Slovensko',
    price: 2500,
    codPrice: 0,
    country: Country.SK,
    payments: [BANK_DEPOSIT_PAYMENT],
    minWeight: 50,
    weightTiers: {
      100: 3245,
      250: 4361,
      500: 6552,
    },
  },
];
