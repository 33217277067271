import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'shared-delete-dialog',
  templateUrl: './delete-dialog.component.html',
})
export class DeleteDialogComponent {
  label = '';

  constructor(public dialog: MatDialogRef<DeleteDialogComponent>) {}

  no(): void {
    this.dialog.close(false);
  }

  yes(): void {
    this.dialog.close(true);
  }
}
