import { DatePipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { get, isArray } from 'lodash';
import { GridField } from 'shared-ui/models/grid-field';
import { LocaleCurrencyPipe } from 'shared-ui/pipes/locale-currency.pipe';
import { Translator } from 'shared-ui/providers/translator';

@Pipe({
  name: 'fieldValue',
})
export class DatagridValuePipe implements PipeTransform {
  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private translator: Translator
  ) {}

  transform(item: Record<string, any>, field: GridField): string | null {
    const t = (message: string) => this.translator.translate(message);
    let value: string | string[] = get(item, field.key);
    if (field.options?.length) value = this.mapOptions(value, field);
    if (isArray(value)) {
      return field.translate ? value.map(v => t(v)).join() : value.join();
    } else if (field.type === 'date') {
      return new DatePipe(this.locale).transform(value, 'mediumDate');
    } else if (field.type === 'datetime') {
      return new DatePipe(this.locale).transform(value, 'medium');
    } else if (field.type === 'select') {
      return field.translate ? t(value) : value;
    } else if (field.type === 'currency') {
      return new LocaleCurrencyPipe(this.locale).transform(value as any);
    } else if (field.type === 'number') {
      return value;
    } else if (field.type === 'text') {
      return field.translate ? t(value) : value;
    } else {
      throw new Error(`Unsupported field type: ${field.type}`);
    }
  }

  private mapOptions(input: string | string[], field: GridField): string | string[] {
    const values = isArray(input) ? input : [input];
    const result = values.map(value => field.options?.find(option => option.value === value)?.label ?? value);
    return isArray(input) ? result : result[0];
  }
}
