import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { CategoryParamEntity } from '@shared/domain/products/category-param.entity';
import { productAvailabilities } from '@shared/domain/products/constants';
import { ProductEntity } from '@shared/domain/products/product.entity';
import { CategoriesService } from 'shared-ui/domain/products/categories.service';
import { ManufacturersService } from 'shared-ui/domain/products/manufacturers.service';
import { ProductsService } from 'shared-ui/domain/products/products.service';
import { orderBy, round } from 'lodash';
import { map } from 'rxjs/operators';
import { BaseComponent } from 'shared-ui/components/base-component';
import { VAT } from 'shared-ui/models/vat';
import { FlashMessages } from 'shared-ui/providers/flash-messages';
import { FormlyBuilder } from 'shared-ui/providers/formly-builder';

@Component({
  selector: 'admin-product-detail-params',
  templateUrl: './product-detail-params.component.html',
})
export class ProductDetailParamsComponent extends BaseComponent implements OnChanges {
  categoryParams: CategoryParamEntity[] = [];
  form = new FormGroup({});
  fields: FormlyFieldConfig[] = [];
  isSaving = false;
  @Input()
  product?: ProductEntity;
  @Output()
  readonly closed = new EventEmitter();

  constructor(
    private dataService: ProductsService,
    private categoriesService: CategoriesService,
    private manufacturersService: ManufacturersService,
    private flashMessages: FlashMessages,
    private formlyBuilder: FormlyBuilder
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.product) {
      this.subscribe(this.getCategoryParams(), params => {
        this.categoryParams = params;
        this.setupFields();
      });
    }
  }

  close() {
    this.closed.emit();
  }

  save() {
    this.form.markAllAsTouched();
    if (this.product && this.form.valid) {
      this.isSaving = true;
      this.subscribe(this.dataService.update(this.product._id, this.product), () => {
        this.flashMessages.show('Changes were saved.');
        this.isSaving = false;
        this.closed.emit();
      });
    }
  }

  private getCategories() {
    return this.categoriesService.getCategoriesWithPaths().pipe(map(items => orderBy(items, 'path')));
  }

  private getCategoryParams() {
    return this.categoriesService.getCategoryParams(this.product?.categoryId ?? '');
  }

  private getManufacturers() {
    return this.manufacturersService.find().pipe(map(value => orderBy(value.items, 'name')));
  }

  private setupFields() {
    const fields: FormlyFieldConfig[] = [
      {
        key: 'name',
        type: 'input',
        props: { label: 'Name', required: true },
      },
      {
        key: 'manufacturerId',
        type: 'select',
        props: {
          label: 'Manufacturer',
          required: true,
          options: this.getManufacturers(),
          valueProp: '_id',
          labelProp: 'name',
        },
      },
      {
        key: 'categoryId',
        type: 'select',
        props: {
          label: 'Category',
          required: true,
          options: this.getCategories(),
          valueProp: '_id',
          labelProp: 'path',
        },
      },
      {
        key: 'shortDescription',
        type: 'textarea',
        props: { label: 'Short Description', rows: 4 },
      },
      {
        key: 'price',
        type: 'input',
        props: {
          label: 'Price',
          type: 'number',
          required: true,
          min: 0,
        },
        expressions: {
          'props.description': (field: FormlyFieldConfig) => {
            const model: ProductEntity = field.model;
            const priceWithVat = round(model.price * VAT, 2);
            return `${priceWithVat} s DPH`;
          },
        },
      },
      {
        key: 'purchasePrice',
        type: 'input',
        props: {
          label: 'Purchase Price',
          type: 'number',
          min: 0,
        },
        expressions: {
          'props.description': (field: FormlyFieldConfig) => {
            const model: ProductEntity = field.model;
            const priceWithVat = round((model.purchasePrice ?? 0) * VAT, 2);
            return `${priceWithVat} s DPH`;
          },
        },
      },
      {
        key: 'standardPrice',
        type: 'input',
        props: {
          label: 'Standard Price',
          type: 'number',
          min: 0,
        },
        expressions: {
          'props.description': (field: FormlyFieldConfig) => {
            const model: ProductEntity = field.model;
            const priceWithVat = round((model.standardPrice ?? 0) * VAT, 2);
            return `${priceWithVat} s DPH`;
          },
        },
      },
      {
        key: 'weight',
        type: 'input',
        props: {
          label: 'Weight',
          description: '[kg]',
          type: 'number',
          min: 0,
        },
      },
      {
        key: 'dimensions.length',
        type: 'input',
        props: {
          label: 'Length',
          description: '[mm]',
          type: 'number',
          min: 0,
        },
      },
      {
        key: 'dimensions.width',
        type: 'input',
        props: {
          label: 'Width',
          description: '[mm]',
          type: 'number',
          min: 0,
        },
      },
      {
        key: 'dimensions.height',
        type: 'input',
        props: {
          label: 'Height',
          description: '[mm]',
          type: 'number',
          min: 0,
        },
      },
      {
        key: 'stock',
        type: 'input',
        props: {
          label: 'Stock',
          description: '[ks]',
          type: 'number',
          min: 0,
        },
      },
      {
        key: 'availability',
        type: 'select',
        props: {
          label: 'Delivery Days',
          options: productAvailabilities,
          valueProp: 'id',
          labelProp: 'label',
        },
      },
      {
        key: 'ean',
        type: 'input',
        props: { label: 'EAN' },
      },
    ];

    this.categoryParams.forEach(param => {
      const type: string = param.type === 'choose' || param.type === 'check' ? 'select' : 'input';
      const options = param.type === 'check' ? this.getCheckOptions() : this.getSelectOptions(param);
      const inputType: string = param.type === 'range' ? 'number' : 'text';
      fields.push({
        key: `params.${param.id}`,
        type,
        props: { label: param.label, type: inputType, options },
      });
    });

    fields.push({
      key: 'buyForbidden',
      type: 'checkbox',
      props: {
        label: 'Disable the purchase option',
        appearance: '',
      },
    });

    this.fields = this.formlyBuilder.create(fields);
  }

  private getCheckOptions() {
    return [
      { value: 1, label: this.formlyBuilder.translate('Yes') },
      { value: 0, label: this.formlyBuilder.translate('No') },
    ];
  }

  private getSelectOptions(param: CategoryParamEntity) {
    const def = param.def ?? '';
    const options = def.split(',').map(item => {
      return { value: item, label: item };
    });
    return [{ value: null, label: '' }, ...options];
  }
}
