import { Injectable } from '@angular/core';
import { ProductGridEntity } from '@shared/domain/products/product-grid.entity';
import { ProductEntity } from '@shared/domain/products/product.entity';
import { FileInfo } from '@shared/domain/common/file-info';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { catchError, delay, map } from 'rxjs/operators';
import { CrudHttpService } from 'shared-ui/providers/crud-http-service';

@Injectable()
export class ProductsService extends CrudHttpService<ProductEntity> {
  protected baseUrl = 'products';
  protected entityClass = ProductEntity;

  getProducts(): Observable<{ items: ProductGridEntity[]; total: number }> {
    const query = `products/grid`;
    return this.http.get<{ items: ProductGridEntity[]; total: number }>(query).pipe(
      delay(this.DELAY),
      catchError(error => this.handleQueryError(error, { total: 0, items: [] }))
    );
  }

  uploadDefaultImage(file: File, id: string) {
    const data = new FormData();
    data.append('files', file);
    return this.http.post<ProductEntity>(`${this.baseUrl}/${id}/image`, data);
  }

  uploadImages(fileList: FileList, id: string) {
    const data = new FormData();
    Array.from(fileList).forEach(file => data.append('files', file));
    return this.http.post<string[]>(`${this.baseUrl}/${id}/images-upload`, data);
  }

  deleteImages(files: string[], id: string) {
    return this.http.post<string[]>(`${this.baseUrl}/${id}/images-delete`, { files });
  }

  getFiles(id: string) {
    return this.http.get<FileInfo[]>(`${this.baseUrl}/${id}/files-get`);
  }

  uploadFiles(fileList: FileList, id: string) {
    const data = new FormData();
    Array.from(fileList).forEach(file => data.append('files', file));
    return this.http.post<string[]>(`${this.baseUrl}/${id}/files-upload`, data);
  }

  deleteFiles(files: string[], id: string) {
    return this.http.post<string[]>(`${this.baseUrl}/${id}/files-delete`, { files });
  }

  duplicate(id: string, name: string, includeFiles = true): Observable<ProductEntity> {
    const body = { id, name, includeFiles };
    return this.http.post<ProductEntity>(`${this.baseUrl}/duplicate`, body).pipe(
      delay(this.DELAY),
      map(item => (this.entityClass ? plainToInstance(this.entityClass, item) : item))
    );
  }
}
