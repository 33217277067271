import { BaseEntity } from '@shared/entity/base-entity';
import { Field } from '@shared/model/field';

export class OrderProductEntity extends BaseEntity {
  @Field(true, Number)
  count!: number;

  @Field(true, String)
  id!: string;

  @Field(false, String)
  image?: string;

  @Field(true, String)
  name!: string;

  @Field(true, Number)
  price!: number;
}
