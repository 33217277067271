import { ConfigOption, FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyLabelComponent } from 'shared-ui/components/forms/formly-label.component';
import { FormlyRepeatComponent } from 'shared-ui/components/forms/formly-repeat.component';
import { FormlyRichtextComponent } from 'shared-ui/components/forms/formly-richtext.component';
import { Params, Translator } from 'shared-ui/providers/translator';
import { AppValidators } from 'shared-ui/validators/app-validators';

export function formlyConfig(translator: Translator): ConfigOption {
  const t = (message: string, params?: Params) => translator.translate(message, params);
  return {
    validationMessages: [
      { name: 'required', message: t('Form field is required.') },
      { name: 'number', message: t('Value is not valid number.') },
      { name: 'email', message: t('Value is not valid email.') },
      {
        name: 'min',
        message: (error: Error, field: FormlyFieldConfig) =>
          t('Minimum value is [0].', { 0: field.props?.min }),
      },
      {
        name: 'max',
        message: (error: Error, field: FormlyFieldConfig) =>
          t('Maximum value is [0].', { 0: field.props?.max }),
      },
      {
        name: 'minLength',
        message: (error: Error, field: FormlyFieldConfig) =>
          t('Minimum length is [0].', { 0: field.props?.minLength }),
      },
      {
        name: 'maxLength',
        message: (error: Error, field: FormlyFieldConfig) =>
          t('Maximum length is [0].', { 0: field.props?.maxLength }),
      },
    ],
    validators: [
      { name: 'number', validation: AppValidators.number() },
      { name: 'email', validation: AppValidators.email },
    ],
    types: [
      { name: 'label', component: FormlyLabelComponent },
      { name: 'richtext', component: FormlyRichtextComponent },
      { name: 'repeat', component: FormlyRepeatComponent },
    ],
  };
}
