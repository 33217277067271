import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'shared-formly-label',
  template: `
    <b>{{(field.props?.label ?? '') | t}}</b>
    <br>
    <span>{{formControl.value }}</span>
  `,
})
export class FormlyLabelComponent extends FieldType {}
