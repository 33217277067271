import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ManufacturerEntity } from '@shared/domain/products/manufacturer.entity';
import { BaseDetailComponent } from 'shared-ui/components/base-detail-component';
import { ManufacturersService } from 'shared-ui/domain/products/manufacturers.service';
import { FlashMessages } from 'shared-ui/providers/flash-messages';
import { FormlyBuilder } from 'shared-ui/providers/formly-builder';

@Component({
  selector: 'admin-manufacturers-detail',
  templateUrl: './manufacturers-detail.component.html',
})
export class ManufacturersDetailComponent extends BaseDetailComponent<ManufacturerEntity> {
  constructor(
    protected dataService: ManufacturersService,
    route: ActivatedRoute,
    router: Router,
    formlyBuilder: FormlyBuilder,
    flashMessages: FlashMessages
  ) {
    super(dataService, route, router, formlyBuilder, flashMessages);
  }

  getEntityClass() {
    return ManufacturerEntity;
  }

  getItemTitle(item: ManufacturerEntity): string {
    return item.name;
  }
}
