import { BaseEntity } from '@shared/entity/base-entity';
import { Field } from '@shared/model/field';

export class AddressEntity extends BaseEntity {
  @Field(true, String)
  name!: string;

  @Field(true, String)
  street!: string;

  @Field(true, String)
  city!: string;

  @Field(true, String)
  postalcode!: string;

  @Field(true, String)
  country!: string;
}
