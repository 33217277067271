import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { UserEntity } from '@shared/domain/users/user.entity';
import { unset } from 'lodash';
import { BaseDetailComponent } from 'shared-ui/components/base-detail-component';
import { AdminUsersService } from 'shared-ui/domain/users/admin-users.service';
import { FlashMessages } from 'shared-ui/providers/flash-messages';
import { FormlyBuilder } from 'shared-ui/providers/formly-builder';

@Component({
  selector: 'admin-users-detail',
  templateUrl: './users-detail.component.html',
})
export class UsersDetailComponent extends BaseDetailComponent<UserEntity> {
  constructor(
    protected dataService: AdminUsersService,
    route: ActivatedRoute,
    router: Router,
    formlyBuilder: FormlyBuilder,
    flashMessages: FlashMessages
  ) {
    super(dataService, route, router, formlyBuilder, flashMessages);
  }

  getEntityClass() {
    return UserEntity;
  }

  getItemTitle(item: UserEntity): string {
    return item.email;
  }

  protected onFormFieldsCreate(fields: Record<keyof UserEntity, FormlyFieldConfig>) {
    unset(fields, 'poolId');
    unset(fields, 'hashedPassword');
    fields.email.validators = { validation: ['email'] };
    fields.email.asyncValidators = { uniqueEmail: this.dataService.createUniqueEmailValidator() };
  }
}
