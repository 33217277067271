import { PaymentModel } from '@shared/domain/orders/payment.model';
import { BaseEntity } from '@shared/entity/base-entity';
import { Field } from '@shared/model/field';

export class PaymentEntity extends BaseEntity implements PaymentModel {
  @Field(true, String)
  id!: string;

  @Field(true, String)
  title!: string;

  @Field(true, Number)
  price!: number;
}
