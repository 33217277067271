import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FormlyModule } from '@ngx-formly/core';
import { AuthForgotComponent } from 'admin/app/core/components/auth-forgot/auth-forgot.component';
import { AuthResetComponent } from 'admin/app/core/components/auth-reset/auth-reset.component';
import { HeaderComponent } from 'admin/app/core/components/header/header.component';
import { HomepageComponent } from 'admin/app/core/components/homepage/homepage.component';
import { LoginComponent } from 'admin/app/core/components/login/login.component';
import { MenuComponent } from 'admin/app/core/components/menu/menu.component';
import { UserProfileComponent } from 'admin/app/core/components/user-profile/user-profile.component';
import { AuthGuard } from 'admin/app/core/providers/auth.guard';
import { AuthService } from 'admin/app/core/providers/auth.service';
import { SharedUiModule } from 'shared-ui/shared-ui.module';

@NgModule({
  declarations: [
    AuthForgotComponent,
    AuthResetComponent,
    HeaderComponent,
    HomepageComponent,
    LoginComponent,
    MenuComponent,
    UserProfileComponent,
  ],
  providers: [AuthGuard, AuthService],
  imports: [CommonModule, HttpClientModule, FormlyModule, ReactiveFormsModule, RouterModule, SharedUiModule],
  exports: [HeaderComponent, HomepageComponent, LoginComponent, MenuComponent],
})
export class AdminCoreModule {}
