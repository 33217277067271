import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormlyModule } from '@ngx-formly/core';
import { CatalogRoutingModule } from 'admin/app/views/catalog/catalog-routing.module';
import { CategoriesDeleteDialogComponent } from 'admin/app/views/catalog/components/categories/detail/delete-dialog.component';
import { CategoriesDetailComponent } from 'admin/app/views/catalog/components/categories/detail/detail.component';
import { CategoriesFormComponent } from 'admin/app/views/catalog/components/categories/form/form.component';
import { CategoriesNewComponent } from 'admin/app/views/catalog/components/categories/new/new.component';
import { CategoriesOverviewComponent } from 'admin/app/views/catalog/components/categories/overview/overview.component';
import { ManufacturersDetailComponent } from 'admin/app/views/catalog/components/manufacturers-detail/manufacturers-detail.component';
import { ManufacturersListComponent } from 'admin/app/views/catalog/components/manufacturers-list/manufacturers-list.component';
import { ProductsListComponent } from 'admin/app/views/catalog/components/products-list/products-list.component';
import { ProductsMassEditComponent } from 'admin/app/views/catalog/components/products-mass-edit/products-mass-edit.component';
import { ProductsNewComponent } from 'admin/app/views/catalog/components/products/new/new.component';
import { ProductAccessoriesComponent } from 'admin/app/views/catalog/components/products/product-accessories/product-accessories.component';
import { ProductCopyComponent } from 'admin/app/views/catalog/components/products/product-copy/product-copy.component';
import { ProductDetailEditorComponent } from 'admin/app/views/catalog/components/products/product-detail/product-detail-editor/product-detail-editor.component';
import { ProductDetailFilesComponent } from 'admin/app/views/catalog/components/products/product-detail/product-detail-files/product-detail-files.component';
import { ProductDetailImagesComponent } from 'admin/app/views/catalog/components/products/product-detail/product-detail-images/product-detail-images.component';
import { ProductDetailParamsComponent } from 'admin/app/views/catalog/components/products/product-detail/product-detail-params/product-detail-params.component';
import { ProductDetailComponent } from 'admin/app/views/catalog/components/products/product-detail/product-detail.component';
import { CategoriesService } from 'shared-ui/domain/products/categories.service';
import { ManufacturersService } from 'shared-ui/domain/products/manufacturers.service';
import { ProductsService } from 'shared-ui/domain/products/products.service';
import { SharedUiModule } from 'shared-ui/shared-ui.module';

@NgModule({
  declarations: [
    CategoriesDetailComponent,
    CategoriesDeleteDialogComponent,
    CategoriesOverviewComponent,
    CategoriesFormComponent,
    CategoriesNewComponent,
    ProductsListComponent,
    ProductsNewComponent,
    ProductAccessoriesComponent,
    ProductDetailComponent,
    ProductDetailParamsComponent,
    ProductDetailEditorComponent,
    ProductDetailImagesComponent,
    ProductDetailFilesComponent,
    ProductCopyComponent,
    ManufacturersListComponent,
    ManufacturersDetailComponent,
    ProductsMassEditComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    SharedUiModule,
    CatalogRoutingModule,
    MatCheckboxModule,
    FormlyModule,
  ],
  providers: [CategoriesService, ProductsService, ManufacturersService],
})
export class AdminCatalogModule {}
