<div class="row">
  <div class="col-auto mb-3">
    <shared-dropdown #dropdown>
      <button trigger mat-stroked-button color="primary">
        {{ 'Filter' | t }}
        <icon icon="arrow_drop_down"></icon>
      </button>
      <ng-container [ngTemplateOutlet]="filterForm" [ngTemplateOutletContext]="{ dropdown }"></ng-container>
    </shared-dropdown>
  </div>
  <mat-chip-listbox [selectable]="false" class="col-auto mb-3">
    <ng-container *ngFor="let field of formFields">
      <ng-container *ngIf="formValues[field.key]">
        <mat-chip-option
          class="m-0 mr-2"
          [selected]="true"
          [removable]="true"
          (click)="dropdown.open()"
          (removed)="remove(field)">
          <span> {{ field.label | t }}: {{ formValues | fieldValue: field }} </span>
          <button matChipRemove><icon icon="clear" /></button>
        </mat-chip-option>
      </ng-container>
    </ng-container>
  </mat-chip-listbox>
</div>

<ng-template #filterForm let-dropdown="dropdown">
  <mat-card class="p-2" [formGroup]="form">
    <div class="row" *ngFor="let field of formFields">
      <div class="col" [ngSwitch]="field.type">
        <ng-container *ngSwitchCase="'date'">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>{{ field.label | t }}</mat-label>
            <input matInput [formControlName]="field.key" [matDatepicker]="picker" autocomplete="off" />
            <icon matSuffix matRipple icon="calendar_month" (click)="picker.open()"></icon>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </ng-container>
        <ng-container *ngSwitchCase="'datetime'">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>{{ field.label | t }}</mat-label>
            <input matInput [formControlName]="field.key" [matDatepicker]="picker" autocomplete="off" />
            <icon matSuffix matRipple icon="calendar_month" (click)="picker.open()"></icon>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </ng-container>
        <ng-container *ngSwitchCase="'select'">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>{{ field.label | t }}</mat-label>
            <mat-select [formControlName]="field.key" [multiple]="true">
              <mat-option *ngFor="let opt of field.options" [value]="opt.value">
                {{ field.translate ? (opt.label | t) : opt.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
        <ng-container *ngSwitchCase="'number'">
          <div class="row">
            <mat-form-field appearance="outline" floatLabel="always" class="col">
              <mat-label>{{ field.label | t }} od</mat-label>
              <input
                #inputF
                matInput
                type="number"
                [value]="formValues[field.key]?.[0]"
                (change)="changeField([inputF.value, 'from'], field)"
                (keyup)="keyup($event, dropdown)" />
            </mat-form-field>
            <mat-form-field appearance="outline" floatLabel="always" class="col">
              <mat-label>{{ field.label | t }} do</mat-label>
              <input
                #inputT
                matInput
                type="number"
                [value]="formValues[field.key]?.[1]"
                (change)="changeField([inputT.value, 'to'], field)"
                (keyup)="keyup($event, dropdown)" />
            </mat-form-field>
          </div>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>{{ field.label | t }}</mat-label>
            <input matInput [formControlName]="field.key" type="text" autocomplete="off" />
          </mat-form-field>
        </ng-container>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <button mat-stroked-button (click)="reset(); dropdown.close()">{{ 'Reset' | t }}</button>
      </div>
      <div class="col text-right">
        <button mat-raised-button color="primary" (click)="apply(); dropdown.close()">{{ 'Apply' | t }}</button>
      </div>
    </div>
  </mat-card>
</ng-template>
