import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { InspectionEntity } from '@shared/domain/inspections/inspection.entity';
import { InspectionsService } from 'shared-ui/domain/inspections/inspections.service';
import { merge } from 'lodash';
import { BaseDetailComponent } from 'shared-ui/components/base-detail-component';
import { FlashMessages } from 'shared-ui/providers/flash-messages';
import { FormlyBuilder } from 'shared-ui/providers/formly-builder';

@Component({
  selector: 'app-inspections-detail',
  templateUrl: './inspections-detail.component.html',
})
export class InspectionsDetailComponent extends BaseDetailComponent<InspectionEntity> {
  constructor(
    protected dataService: InspectionsService,
    route: ActivatedRoute,
    router: Router,
    formlyBuilder: FormlyBuilder,
    flashMessages: FlashMessages
  ) {
    super(dataService, route, router, formlyBuilder, flashMessages);
  }

  getEntityClass() {
    return InspectionEntity;
  }

  getItemTitle(item: InspectionEntity): string {
    return item.datetime.toISOString();
  }

  protected onFormFieldsCreate(fields: Record<keyof InspectionEntity, FormlyFieldConfig>) {
    fields.boilerType.type = 'select';
    fields.boilerType.props = merge(fields.boilerType.props, {
      options: this.dataService.getTypeOptions(),
    });

    fields.status.type = 'select';
    fields.status.props = merge(fields.status.props, {
      options: this.dataService.getStatusOptions(),
    });
  }
}
