import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'shared-formly-richtext',
  template: `
    <label [for]="field.id">
      {{ field.props?.label }}
      <span aria-hidden="true" *ngIf="field.props?.required">*</span>
    </label>
    <quill-editor [formControl]="formControl" [styles]="field.props?.styles"></quill-editor>
    <mat-error *ngIf="showError">
      <formly-validation-message [field]="field"></formly-validation-message>
    </mat-error>
  `,
})
export class FormlyRichtextComponent extends FieldType {
  get formControl() {
    return super.formControl as FormControl;
  }
}
