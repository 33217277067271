export const environment = {
  production: true,
  apiUrl: 'https://berop-admin-api-prod-kt6tyifvma-ey.a.run.app',
  filesUrl: 'https://storage.googleapis.com/berop-shop-products-prod',
  imagesUrl: 'https://storage.googleapis.com/berop-shop-products-prod',
  sentry: {
    enabled: true,
    dsn: 'https://3d07a51300a74a8c97c129010c43714d@o290297.ingest.sentry.io/5722037',
  },
};
