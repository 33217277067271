import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SortDirection } from '@angular/material/sort';
import { ManufacturerEntity } from '@shared/domain/products/manufacturer.entity';
import { BaseListComponent } from 'shared-ui/components/base-list-component';
import { ManufacturersService } from 'shared-ui/domain/products/manufacturers.service';
import { FlashMessages } from 'shared-ui/providers/flash-messages';

@Component({
  selector: 'admin-manufacturers-list',
  templateUrl: './manufacturers-list.component.html',
})
export class ManufacturersListComponent extends BaseListComponent<ManufacturerEntity> {
  sortField = 'name';
  sortDirection: SortDirection = 'asc';

  constructor(
    protected dataService: ManufacturersService,
    protected dialogService: MatDialog,
    protected flashMessages: FlashMessages
  ) {
    super(dataService, dialogService, flashMessages);
  }

  getEntityClass() {
    return ManufacturerEntity;
  }

  getFieldsOmit() {
    return ['_id', 'createdAt', 'updatedAt', 'description'];
  }
}
