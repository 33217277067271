import { BaseEntity } from '@shared/entity/base-entity';
import { Field } from '@shared/model/field';

export class InspectionCustomerEntity extends BaseEntity {
  @Field(true, String)
  name!: string;

  @Field(true, String)
  address!: string;

  @Field(true, String)
  phone!: string;

  @Field(true, String)
  email!: string;
}
