import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-catalog-categories-delete-dialog',
  templateUrl: './delete-dialog.component.html',
})
export class CategoriesDeleteDialogComponent {
  constructor(public dialog: MatDialogRef<CategoriesDeleteDialogComponent>) {}

  onNoClick(): void {
    this.dialog.close();
  }
}
