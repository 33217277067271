import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { ApiTableDataSource } from 'shared-ui/components/datagrid/api-table-data-source';
import { GridField } from 'shared-ui/models/grid-field';
import { StorageService } from 'shared-ui/providers/storage-service';
import { Translator } from 'shared-ui/providers/translator';

@Component({
  selector: 'shared-datagrid',
  templateUrl: './datagrid.component.html',
  styleUrls: ['./datagrid.component.scss'],
})
export class DatagridComponent implements AfterViewInit, OnInit, OnChanges {
  @Input() datasource!: ApiTableDataSource<any>;
  @Input() fields: GridField[] = [];
  @Input() sortField: string = 'createdAt';
  @Input() sortDirection: SortDirection = 'desc';
  @Input() templates: Record<string, TemplateRef<any>> = {};
  @Input() isLoading = false;
  @Input() height = '';
  @Input() filterName?: string;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  filter = new UntypedFormGroup({});

  constructor(
    private storage: StorageService,
    private translator: Translator
  ) {}

  ngOnInit() {
    this.updateFormControls();
  }

  ngOnChanges(changes: Record<keyof this, SimpleChange>) {
    if (changes.fields) {
      this.updateFormControls();
    }
    if (changes.datasource && this.paginator && this.sort) {
      this.updateDatasource();
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (!this.datasource) {
        throw new Error('Missing datasource input!');
      }
      this.updateDatasource();
    });
  }

  private updateDatasource() {
    this.paginator._intl = this.translator.getPaginatorIntl();
    this.datasource.filter = this.filter;
    this.datasource.paginator = this.paginator;
    this.datasource.sort = this.sort;
    this.datasource.refresh();
  }

  private updateFormControls() {
    this.fields.forEach(field => {
      this.filter.addControl(field.key, new UntypedFormControl(null));
    });
    if (this.filterName) {
      const valuesStored = JSON.parse(this.storage.getItem(this.filterName ?? '') ?? '{}');
      this.filter.patchValue(valuesStored);
    }
  }
}
