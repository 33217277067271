<div class="action-bar action-bar-full-w">
  <span class="action-bar-title">{{ 'Users' | t }}</span>
  <a *ngIf="canCreate" mat-button color="primary" routerLink="new">
    <icon icon="add_box"></icon><span class="label">{{ 'Add' | t }}</span>
  </a>
</div>

<shared-datagrid
  filterName="filter-users"
  [datasource]="datasource"
  [fields]="fields"
  [sortField]="sortField"
  [sortDirection]="sortDirection"
  [templates]="{ actions, active }"
  [isLoading]="isLoading"></shared-datagrid>

<ng-template #actions let-item="item">
  <button *ngIf="canUpdate" mat-icon-button color="primary" [routerLink]="item['_id']">
    <icon icon="edit"></icon>
  </button>
  <button *ngIf="canDelete" mat-icon-button color="warn" (click)="delete(item)">
    <icon icon="delete_outline"></icon>
  </button>
</ng-template>

<ng-template #active let-item="item">
  <icon *ngIf="item.active" icon="check_circle" style="font-size: 24px; color: green"></icon>
  <icon *ngIf="!item.active" icon="cancel" style="font-size: 24px; color: orangered"></icon>
</ng-template>
