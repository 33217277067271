<div>
  <h3>{{ 'Default Image' | t }}</h3>

  <form>
    <input #fileInput type="file" accept="image/*" class="d-none" (change)="defaultFileChange(fileInput)" />
    <button type="button" mat-raised-button color="primary" (click)="fileInput.click()">
      {{ 'Choose Image' | t }}
    </button>
    <button *ngIf="defaultFileName" type="button" mat-button color="primary" (click)="resetDefault()">
      {{ 'Cancel' | t }}
    </button>
    <mat-list *ngIf="defaultFileName">
      <mat-list-item>
        <icon mat-list-icon icon="image"></icon>
        <div mat-line>{{ defaultFileName }}</div>
      </mat-list-item>
    </mat-list>
    <div *ngIf="defaultFileName">
      <button type="button" mat-raised-button color="accent" (click)="uploadDefaultImage()">
        {{ 'Upload Image' | t }}
      </button>
    </div>
  </form>

  <div class="row mt-3" *ngIf="product && product.defaultImage">
    <div class="col-md-4">
      <mat-card>
        <a [href]="product.defaultImage | fileUrl: 'products' : product._id" target="_blank">
          <img [src]="product.defaultImage | fileUrl: 'products' : product._id" alt="" />
        </a>
      </mat-card>
    </div>
  </div>

  <h3 class="mt-3">{{ 'Images' | t }}</h3>

  <form>
    <input #filesInput type="file" accept="image/*" multiple="" class="d-none" (change)="filesChange(filesInput)" />
    <button type="button" mat-raised-button color="primary" (click)="filesInput.click()">
      {{ 'Choose Images' | t }}
    </button>
    <button *ngIf="filesNames.length" type="button" mat-button color="primary" (click)="reset()">
      {{ 'Cancel' | t }}
    </button>
    <mat-list>
      <mat-list-item *ngFor="let item of filesNames">
        <icon mat-list-icon icon="image"></icon>
        <div mat-line>{{ item }}</div>
      </mat-list-item>
    </mat-list>
    <div *ngIf="filesNames.length">
      <button type="button" mat-raised-button color="accent" (click)="uploadImages()">{{ 'Upload Files' | t }}</button>
    </div>
  </form>

  <div class="row mt-3" *ngIf="product && product.images.length">
    <div class="col-md-4" *ngFor="let item of product.images" style="margin-bottom: 30px">
      <mat-card>
        <a [href]="item | fileUrl: 'products' : product._id" target="_blank">
          <img [src]="item | fileUrl: 'products' : product._id" alt="" />
        </a>
        <button
          mat-mini-fab
          color="warn"
          [matTooltip]="'Delete' | t"
          (click)="deleteImage(item)"
          style="position: absolute; top: -15px; right: -15px">
          <icon icon="clear"></icon>
        </button>
      </mat-card>
    </div>
  </div>
</div>
