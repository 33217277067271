<div class="action-bar action-bar-full-w">
  <span class="action-bar-title">{{ 'Orders' | t }}</span>
  <a *ngIf="canCreate" mat-button color="primary" routerLink="new">
    <icon icon="add_box"></icon><span class="label">{{ 'Add' | t }}</span>
  </a>
</div>

<shared-datagrid
  filterName="filter-orders"
  [datasource]="datasource"
  [fields]="fields"
  [sortField]="sortField"
  [sortDirection]="sortDirection"
  [templates]="{ customer, total, state }"
  [isLoading]="isLoading"></shared-datagrid>

<ng-template #customer let-item="item">
  {{ item.customer.firstname }}
  {{ item.customer.lastname }}
</ng-template>

<ng-template #total let-item="item">
  {{ item.total | localeCurrency }}
</ng-template>

<ng-template #state let-item="item">
  {{ stateLabelsEnum[item.state] | t }}
</ng-template>
