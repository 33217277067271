import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryParamEntity } from '@shared/domain/products/category-param.entity';
import { CategoryEntity } from '@shared/domain/products/category.entity';
import { CategoriesDeleteDialogComponent } from 'admin/app/views/catalog/components/categories/detail/delete-dialog.component';
import { paramTypes } from 'admin/app/views/catalog/models/param-types';
import { CategoriesService } from 'shared-ui/domain/products/categories.service';
import { FlashMessages } from 'shared-ui/providers/flash-messages';
import { FormlyBuilder } from 'shared-ui/providers/formly-builder';

type CategoryParamFields = Omit<CategoryParamEntity, 'validate' | 'validateSync'>;

@Component({
  selector: 'app-catalog-categories-detail',
  templateUrl: './detail.component.html',
})
export class CategoriesDetailComponent implements OnInit {
  category?: CategoryEntity;
  categoryId = '';
  formParams = new FormArray([this.formly.createForm<CategoryParamFields>()]);
  form = new FormGroup({ params: this.formParams });
  parentsParams: CategoryParamEntity[] = [];
  paramsTypes = paramTypes;

  constructor(
    private dataService: CategoriesService,
    private route: ActivatedRoute,
    private router: Router,
    private dialogFactory: MatDialog,
    private formly: FormlyBuilder,
    private flashMessages: FlashMessages
  ) {
    this.categoryId = this.route.snapshot.paramMap.get('id') ?? '';
  }

  ngOnInit() {
    this.dataService.getCategoryWithParents(this.categoryId).subscribe(category => {
      this.category = category;
      this.setParentsParams();
      this.params.controls = [];
      category?.productParams.forEach(param => this.addRow(param));
    });
  }

  onDeleteCategory(): void {
    const dialog = this.dialogFactory.open(CategoriesDeleteDialogComponent, {
      width: '300px',
    });
    dialog.afterClosed().subscribe(value => {
      if (value === true) {
        this.deleteCategory();
      }
    });
  }

  onSubmit(formData: Record<string, unknown>) {
    this.dataService.update(this.categoryId, formData).subscribe(
      () => {
        this.flashMessages.show('Changes were saved.');
        this.category = undefined;
        this.ngOnInit();
      },
      () => {
        this.flashMessages.show('Changes were not saved. Try again later.');
      }
    );
  }

  get params() {
    return this.form.controls.params;
  }

  addRow(params?: CategoryParamEntity) {
    const data: CategoryParamEntity = params ?? JSON.parse('{}');
    const row = this.formly.createForm<CategoryParamFields>({
      id: new FormControl(data.id, [Validators.required]),
      label: new FormControl(data.label, [Validators.required]),
      type: new FormControl(data.type, [Validators.required]),
      def: new FormControl(data.def),
    });
    this.params.push(row);
  }

  deleteRow(index: number) {
    this.params.removeAt(index);
  }

  saveParams() {
    this.form.enable();
    if (this.form.invalid) return;
    const values = { productParams: this.form.getRawValue().params };
    this.onSubmit(values);
  }

  protected deleteCategory(): void {
    this.dataService.delete(this.categoryId).subscribe(
      () => {
        this.flashMessages.show('Category was deleted.');
        void this.router.navigate(['catalog/categories']);
      },
      () => {
        this.flashMessages.show('Category was not deleted. Try again later.');
      }
    );
  }

  protected setParentsParams() {
    let params: CategoryParamEntity[] = [];
    let currentCategory = this.category?.parent;
    while (currentCategory) {
      params = [...currentCategory.productParams, ...params];
      currentCategory = currentCategory.parent;
    }
    this.parentsParams = params;
  }
}
