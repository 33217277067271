<h1 mat-dialog-title>{{ 'Confirmation' | t }}</h1>
<div mat-dialog-content>
  <p>{{ 'Delete selected category?' | t }}</p>
</div>
<div mat-dialog-actions>
  <button mat-raised-button button (click)="onNoClick()">{{ 'No' | t }}</button>
  <button mat-raised-button color="warn" [mat-dialog-close]="true">
    {{ 'Delete' | t }}
  </button>
</div>
