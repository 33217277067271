import { Inject, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ENVIRONMENT, SharedEnv } from 'shared-ui/di/environment';

@Pipe({
  name: 'fileUrl',
})
export class FileUrlPipe implements PipeTransform {
  constructor(@Inject(ENVIRONMENT) private env: SharedEnv, private sanitizer: DomSanitizer) {}

  transform(value: string, namespace: string, id: string) {
    const url = `${this.env.filesUrl}/${namespace}/${id}/${value}`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
