import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductEntity } from '@shared/domain/products/product.entity';
import { ProductsService } from 'shared-ui/domain/products/products.service';
import { BaseComponent } from 'shared-ui/components/base-component';
import { FlashMessages } from 'shared-ui/providers/flash-messages';

@Component({
  selector: 'admin-product-accessories',
  templateUrl: './product-accessories.component.html',
  styleUrls: ['./product-accessories.component.scss'],
})
export class ProductAccessoriesComponent extends BaseComponent implements OnInit {
  @Output()
  readonly closed = new EventEmitter();

  @Input()
  product!: ProductEntity;

  @Output()
  accessoriesChanged = new EventEmitter();

  accessories: string[] = [];

  availableProducts: ProductEntity[] = [];

  filter = '';

  products: ProductEntity[] = [];

  isSaving = false;

  constructor(
    private dataService: ProductsService,
    private flashMessages: FlashMessages
  ) {
    super();
  }

  ngOnInit(): void {
    this.accessories = this.product.accessories;
    this.dataService.find().subscribe(value => {
      this.products = value.items;
      this.setAvailableProducts();
    });
  }

  setAvailableProducts(): void {
    const products = this.products.filter(item => this.accessories.indexOf(item._id) === -1);
    const search = this.filter.toLowerCase();
    this.availableProducts = products.filter(item => item.name.toLowerCase().indexOf(search) !== -1);
  }

  setFilter(value: string): void {
    this.filter = value;
    this.setAvailableProducts();
  }

  getProductName(acc: string): string {
    const product = this.products.find(item => acc === item._id);
    if (product) return product.name;
    return '';
  }

  addAcc(acc: string): void {
    this.accessories.push(acc);
    this.accessoriesChanged.emit(this.accessories);
    this.setAvailableProducts();
  }

  removeAcc(acc: string): void {
    this.accessories = this.accessories.filter(item => item !== acc);
    this.accessoriesChanged.emit(this.accessories);
    this.setAvailableProducts();
  }

  close() {
    this.closed.emit();
  }

  save() {
    if (this.product) {
      this.isSaving = true;
      this.product.accessories = this.accessories;
      this.subscribe(this.dataService.update(this.product._id, this.product), () => {
        this.flashMessages.show('Changes were saved.');
        this.isSaving = false;
        this.closed.emit();
      });
    }
  }
}
