import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'admin/app/core/providers/auth.guard';
import { OrdersDetailComponent } from 'admin/app/orders/components/orders-detail/orders-detail.component';
import { OrdersEditComponent } from 'admin/app/orders/components/orders-edit/orders-edit.component';
import { OrdersListComponent } from 'admin/app/orders/components/orders-list/orders-list.component';
import { OrdersService } from 'shared-ui/domain/orders/orders.service';
import { SharedUiModule } from 'shared-ui/shared-ui.module';

const routes: Routes = [
  {
    path: 'orders',
    canActivate: [AuthGuard],
    children: [
      { path: '', component: OrdersListComponent },
      { path: ':id', component: OrdersDetailComponent },
    ],
  },
];

@NgModule({
  declarations: [OrdersDetailComponent, OrdersEditComponent, OrdersListComponent],
  imports: [CommonModule, HttpClientModule, ReactiveFormsModule, SharedUiModule, RouterModule.forChild(routes)],
  providers: [OrdersService],
})
export class AdminOrdersModule {}
