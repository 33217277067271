import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ArticleEntity } from '@shared/domain/articles/article.entity';
import { kebabCase, merge } from 'lodash';
import { BaseDetailComponent } from 'shared-ui/components/base-detail-component';
import { ArticlesService } from 'shared-ui/domain/articles/articles.service';
import { FlashMessages } from 'shared-ui/providers/flash-messages';
import { FormlyBuilder } from 'shared-ui/providers/formly-builder';

@Component({
  selector: 'app-articles-detail',
  templateUrl: './articles-detail.component.html',
})
export class ArticlesDetailComponent extends BaseDetailComponent<ArticleEntity> {
  constructor(
    protected dataService: ArticlesService,
    route: ActivatedRoute,
    router: Router,
    formlyBuilder: FormlyBuilder,
    flashMessages: FlashMessages
  ) {
    super(dataService, route, router, formlyBuilder, flashMessages);
  }

  getEntityClass() {
    return ArticleEntity;
  }

  getItemTitle(item: ArticleEntity): string {
    return item.title;
  }

  protected onFormFieldsCreate(fields: Record<keyof ArticleEntity, FormlyFieldConfig>) {
    fields.content.type = 'richtext';
    fields.content.props = merge(fields.content.props, { styles: { height: '700px' } });

    fields.category.type = 'select';
    fields.category.props = merge(fields.category.props, {
      options: this.dataService.getCategoriesOptions(),
    });

    fields.title.props = merge(fields.title.props, {
      keyup: (field: FormlyFieldConfig) => {
        const model: Partial<ArticleEntity> = field.model;
        this.form.patchValue({ url: kebabCase(model.title) }, { emitEvent: false });
      },
    });
  }
}
