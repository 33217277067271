import { Injectable } from '@angular/core';
import { UserPoolsEnum } from '@shared/domain/users/user-pools.enum';
import { UsersService } from 'shared-ui/domain/users/users.service';

@Injectable()
export class AdminUsersService extends UsersService {
  get poolId(): UserPoolsEnum {
    return UserPoolsEnum.Admin;
  }
}
