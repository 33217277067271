export interface ProductAvailability {
  id: number;
  label: string;
}

export const productAvailabilities: ProductAvailability[] = [
  { id: 1, label: 'Skladem' },
  { id: 3, label: 'Do 3 dnů' },
  { id: 7, label: 'Do týdne' },
  { id: 14, label: 'Do 2 týdnů' },
  { id: 30, label: 'Na objednávku' },
];
