import { Injectable, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { BaseEntity } from '@shared/entity/base-entity';
import { BaseRootEntity } from '@shared/entity/base-root-entity';
import { FieldOptions } from '@shared/model/field';
import { plainToInstance } from 'class-transformer';
import { get } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseComponent } from 'shared-ui/components/base-component';
import { CrudHttpService } from 'shared-ui/providers/crud-http-service';
import { FlashMessages } from 'shared-ui/providers/flash-messages';
import { FormlyBuilder } from 'shared-ui/providers/formly-builder';

@Injectable()
export abstract class BaseDetailComponent<T extends BaseRootEntity> extends BaseComponent implements OnInit {
  item?: T;
  itemId = '';
  itemTitle = '';
  isLoading = false;
  isCreate = true;
  isReadOnly = false;
  form = new UntypedFormGroup({});
  formFields: FormlyFieldConfig[] = [];

  protected constructor(
    protected dataService: CrudHttpService<T>,
    protected route: ActivatedRoute,
    protected router: Router,
    protected formlyBuilder: FormlyBuilder,
    protected flashMessages: FlashMessages
  ) {
    super();
  }

  abstract getEntityClass(): typeof BaseRootEntity;

  ngOnInit(): void {
    this.setFormFields();
    this.subscribe(this.route.paramMap, map => this.onIdChange(map.get('id') ?? 'new'));
  }

  getItemTitle(item: T): string {
    const fields = this.getEntityClass().getFields();
    return get(item, fields[3] ?? '_id');
  }

  getFieldsOmit() {
    return ['_id', 'createdAt', 'updatedAt'];
  }

  save() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      const save = this.isCreate ? this.createItem() : this.updateItem();
      this.isLoading = true;
      this.subscribe(save, result => {
        this.isLoading = false;
        if (result) {
          const message = this.isCreate ? 'Item was successfully created.' : 'Item was successfully updated.';
          this.flashMessages.show(message);
          if (this.isCreate) {
            this.router.navigate(['/articles', result._id]);
          }
        }
      });
    }
  }

  protected createItem(): Observable<T | null> {
    return this.dataService.create(this.form.value);
  }

  protected updateItem(): Observable<T | null> {
    return this.dataService.update(this.itemId, this.form.value).pipe(map(() => this.form.value));
  }

  protected setFormFields() {
    const formFields = this.getFormFields(this.getEntityClass());
    this.onFormFieldsCreate(formFields);
    if (this.isReadOnly) {
      Object.values(formFields).forEach(field => (field.type = 'label'));
    }
    this.formFields = this.formlyBuilder.create(Object.values(formFields));
  }

  protected onIdChange(id: string) {
    this.itemId = id;
    this.isCreate = id === 'new';
    if (this.isCreate) {
      try {
        this.item = plainToInstance(this.getEntityClass(), {}) as T;
      } catch (error) {
        this.item = undefined;
      }
    } else {
      this.loadDetail();
    }
  }

  protected loadDetail() {
    this.isLoading = true;
    this.subscribe(this.dataService.get(this.itemId), item => {
      if (item) {
        this.item = item;
        this.itemTitle = this.getItemTitle(item);
        this.onItemLoad(item);
      }
      this.isLoading = false;
    });
  }

  protected onItemLoad(item: T) {
    // lifecycle
  }

  protected onFormFieldsCreate(fields: Record<string, FormlyFieldConfig>) {
    // lifecycle
  }

  protected getFormFields(
    entityClass: typeof BaseEntity,
    prefix = '',
    result: Record<string, FormlyFieldConfig> = {}
  ): Record<string, FormlyFieldConfig> {
    const fields = entityClass.getFields();
    const fieldsOptions = entityClass.getFieldsOptions();
    const labels = entityClass.getFieldsLabels();
    fields.forEach(key => {
      const options = fieldsOptions[key];
      if (BaseEntity.isType(options.type)) {
        result = Object.assign(result, this.getFormFields(options.type, `${key}.`, result));
      } else if (!this.getFieldsOmit().includes(prefix + key)) {
        result[prefix + key] = this.buildFormField(prefix + key, labels[key], options);
      }
    });
    return result;
  }

  protected buildFormField(key: string, label: string, options: FieldOptions): FormlyFieldConfig {
    const fieldType = options.type.name;
    const type = this.getFieldType(fieldType);
    const inputType = this.getInputType(fieldType);
    return {
      key,
      type,
      validators: { validation: [] },
      props: { type: inputType, label, required: options.required },
    };
  }

  protected getFieldType(type: string) {
    const types: Record<string, string> = {
      Boolean: 'checkbox',
      Date: 'datepicker',
      default: 'input',
    };
    return types[type] ?? types['default'];
  }

  protected getInputType(type: string) {
    const types: Record<string, string> = {
      Number: 'number',
      default: 'text',
    };
    return types[type] ?? types['default'];
  }
}
