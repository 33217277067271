<h3 mat-dialog-title>{{ 'Copy' | t }} {{ 'Product' | t }}</h3>

<div class="mb-1">
  <b>{{ 'Original Name' | t }}:</b>
</div>
<div class="mb-3">{{ product?.name }}</div>

<form [formGroup]="form" (ngSubmit)="duplicate()">
  <formly-form [form]="form" [fields]="formFields"></formly-form>
  <app-form-buttons [isLoading]="isLoading" [showCancel]="true" (cancel)="dialog.close()"></app-form-buttons>
</form>
