import { Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { productAvailabilities } from '@shared/domain/products/constants';
import { ProductEntity } from '@shared/domain/products/product.entity';
import { orderBy, round } from 'lodash';
import { map } from 'rxjs/operators';
import { CategoriesService } from 'shared-ui/domain/products/categories.service';
import { ManufacturersService } from 'shared-ui/domain/products/manufacturers.service';
import { ProductsService } from 'shared-ui/domain/products/products.service';
import { VAT } from 'shared-ui/models/vat';
import { FlashMessages } from 'shared-ui/providers/flash-messages';
import { FormlyBuilder } from 'shared-ui/providers/formly-builder';

@Component({
  selector: 'app-catalog-products-new',
  templateUrl: './new.component.html',
})
export class ProductsNewComponent {
  form = new UntypedFormGroup({});
  formFields: FormlyFieldConfig[] = [];
  isLoading = false;

  constructor(
    private productsService: ProductsService,
    private categoriesService: CategoriesService,
    private manufacturersService: ManufacturersService,
    private flashMessages: FlashMessages,
    private formly: FormlyBuilder,
    private router: Router
  ) {
    this.createForm();
  }

  onSubmit() {
    if (!this.form.valid) {
      return;
    }
    this.isLoading = true;
    this.productsService.create(this.form.value).subscribe(
      product => {
        if (product) {
          this.flashMessages.show('New product was created.');
          void this.router.navigate(['catalog/products/' + product._id]);
        }
      },
      () => {
        this.flashMessages.show('New product was not created. Try again later.');
        this.isLoading = false;
      }
    );
  }

  private getCategories() {
    return this.categoriesService.getCategoriesWithPaths().pipe(map(items => orderBy(items, 'path')));
  }

  private getManufacturers() {
    return this.manufacturersService.find().pipe(map(value => orderBy(value.items, 'name')));
  }

  protected createForm() {
    const fields: FormlyFieldConfig[] = [
      {
        key: 'name',
        type: 'input',
        props: { label: 'Name', required: true },
      },
      {
        key: 'manufacturerId',
        type: 'select',
        props: {
          label: 'Manufacturer',
          required: true,
          options: this.getManufacturers(),
          valueProp: '_id',
          labelProp: 'name',
        },
      },
      {
        key: 'categoryId',
        type: 'select',
        props: {
          label: 'Category',
          required: true,
          options: this.getCategories(),
          valueProp: '_id',
          labelProp: 'path',
        },
      },
      {
        key: 'price',
        type: 'input',
        props: {
          label: 'Price',
          type: 'number',
          required: true,
          min: 0,
        },
        expressions: {
          'props.description': (field: FormlyFieldConfig) => {
            const model: ProductEntity = field.model;
            const priceWithVat = round((model.price ?? 0) * VAT, 2);
            return `${priceWithVat} s DPH`;
          },
        },
      },
      {
        key: 'availability',
        type: 'select',
        props: {
          label: 'Delivery Days',
          options: productAvailabilities,
          valueProp: 'id',
          labelProp: 'label',
        },
      },
      {
        key: 'weight',
        type: 'input',
        props: {
          label: 'Weight',
          description: '[kg]',
          type: 'number',
          min: 0,
        },
      },
    ];
    this.formFields = this.formly.create(fields);
  }
}
